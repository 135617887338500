import React, { useEffect, useState } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Holidays from "date-holidays";

import Popup from "../Popup";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";


const ScheduleCalender = () => {

    let hd = new Holidays('US', 'la');
    let today = new Date();

    const auth = useSelector(state => state.auth);
    // const eventsData = useSelector(state => state.auth.events);

    const schedulerData = useSelector(state => state.scheduler.schedule);
    const [currentYear, setCurrentYear] = useState(today.getFullYear());
    const [showPopup, setShowPopup] = useState(false);
    const [inputDate, setInputDate] = useState({
        date: '',
        time: '',
        schedule_type: ''
    });
    const [events, setEvents] = useState([]);
    const [holidays, setHolidays] = useState([]);
    // const holidays = eventsData.map((holiday)=>{
    //     return(
    //         {
    //             title: holiday.title,
    //             date: holiday.date
    //         }
    //     )
    // });

    const [newEvents, setNewEvents] = useState([]);

    const handleDayRender = (obj) => {
        return (
            <>
                {obj.isPast === false ? <><i className="fa-custom fas fa-plus-circle"> </i>
                    {obj.dayNumberText}</> : obj.dayNumberText}
            </>
        )
    }

    const handleDateClick = (e) => {

        let temp_type = 'monthly';
        if (e.view.type === 'timeGridWeek') {
            temp_type = 'weekly';
        } else if (e.view.type === 'timeGridDay') {
            temp_type = 'daily';
        }

        let postDate = e.dateStr.split("T")[0];

        if (Date.parse(e.date.toDateString()) >= Date.parse(today.toDateString())) {
            setShowPopup(true);
            setInputDate({ ...inputDate, date: postDate, schedule_type: temp_type });
        }

    }

    const onNextPrev = (e) => {
        setCurrentYear(e.endStr.split("-")[0]);
    }

    const fetchCalenderData = async () => {
        await axios({
            method: 'POST',
            url: `${baseURL}get-calender`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                let tmpArr = res.data.data.map((holiday) => {
                    return (
                        {
                            title: holiday.title,
                            date: holiday.date
                        }
                    )
                });

                setHolidays(tmpArr);
                setEvents(newEvents.concat(tmpArr));
            }

        }).catch(error => {

        });
    }


    useEffect(() => {

        // let eventsArr = schedulerData.map((schedule) => {
        //     return (
        //         JSON.parse(schedule.streams).map((stream) => {
        //             return (
        //                 {
        //                     title: stream.title !== '' ? stream.title : schedule.content_stream.title,
        //                     date: stream.scheduled.split(" ")[0]
        //                 }
        //             )
        //         })
        //     )
        // });
        let eventsArr = schedulerData.map((schedule) => {
            const parsedStreams = JSON.parse(schedule.streams);
            console.log(parsedStreams);
            if (Array.isArray(parsedStreams)) {
                return parsedStreams.map((stream) => {
                    return {
                        title: stream.title !== '' ? stream.title : schedule.content_stream.title,
                        date: stream.scheduled.split(" ")[0]
                    };
                });
            } else {
                return [];
            }
        });


        const newArr = [].concat(...eventsArr);

        setNewEvents(newArr);
        setEvents(newEvents.concat(newArr));

    }, [schedulerData]);

    useEffect(() => {

        if (auth.user.membership !== null) {
            let memberships = auth.user.membership.split("__");
            if (memberships.length > 1) {
                fetchCalenderData();
            }
        }

        setEvents(holidays.concat(newEvents));

    }, [holidays.length, newEvents.length]);


    return (
        <>
            <section className="schedule-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                selectable={true}
                                dateClick={(e) => handleDateClick(e)}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                }}
                                dayCellContent={handleDayRender}
                                events={events}
                                datesSet={(e) => onNextPrev(e)}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog" style={{ background: "rgba(0,0,0,0.5)" }}>
                <div className="modal-dialog" role="document" style={{ maxWidth: "1000px" }}>
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Schedule Your Content Stream</h5>
                            <button type="button" className="close" onClick={(e) => setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Popup inputDate={inputDate} setShowPopup={setShowPopup} />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ScheduleCalender;