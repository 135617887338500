import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {addUserKeyword, updateImage, updateImageType, updateThumbnail} from "../../store/actions/videoAction";
import InfiniteScroll from "react-infinite-scroll-component";
import HoverVideoPlayer from "react-hover-video-player";
import {setAlert} from "../../store/actions/alert";
import Alert from "../alert/alert";

const MediaSearchVideo = ( ) => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const textStateData = useSelector(state => state.videoReducer);
    const auth = useSelector(state => state.auth);

    const [imageSearch, setImageSearch] = useState(false);
    const [videoSearch, setVideoSearch] = useState(false);

    const [checkImage, setCheckImage] = useState('');
    const [checkVideo, setCheckVideo] = useState('');

    const [nextStatus, setNextStatus] = useState(false)

    const [searchData, setSearchData] = useState({
        keyword: "",
        page: 1,
        search_type: "image"
    });

    const [imageData, setImageData] = useState({
        data: []
    });

    const [videoData, setVideoData] = useState({
        data: []
    })

    /* Get Selected Index */
    let selectedIndex = textStateData.tiles.findIndex(({is_selected}) => is_selected === "true");

    const onInputChange = (e) => {
        setSearchData({...searchData, keyword: e.target.value, page: 1});

        setImageData({...imageData, data: []});
        setVideoData({...videoData, data: []});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        fetchImgVideo();
    }


    const fetchImgVideo = (page = searchData.page) => {

        if(page > 1 && searchData.search_type === "image"){
            setImageSearch(true);
        }
        else if(page > 1 && searchData.search_type === "video"){
            setVideoSearch(true);
        }

        axios({
            method: 'POST',
            url : `${baseURL}search-media`,
            data: JSON.stringify({keyword: searchData.keyword, page: page, search_type: searchData.search_type}),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                setNextStatus(false);
                if(searchData.search_type === "image"){
                    setImageData({...imageData,data:imageData.data.concat(res.data.data)});
                    setImageSearch(false);
                }
                else if(searchData.search_type === "video"){
                    setVideoData({...videoData, data: videoData.data.concat(res.data.data)});
                    setVideoSearch(false);
                }

                setSearchData({...searchData, page: (searchData.page+1)});
                dispatch(addUserKeyword(searchData.keyword));

            }else{

                if(page > 1){
                    setNextStatus(true);
                }
                setImageSearch(false);
                setVideoSearch(false);
            }
            setLoader(false);

        }).catch(error=>{
            setLoader(false);
        })
    }


    const selectImage = (url,type,video_url=null,index) => {
       if(type==="image"){
           setCheckImage(index)
           dispatch(updateImage(selectedIndex,url));
           if(selectedIndex === 0){
               dispatch(updateThumbnail(url));
           }
       }else if(type ==="video"){
           setCheckVideo(index);
           dispatch(updateImageType(selectedIndex,url,video_url));
           if(selectedIndex === 0){
               dispatch(updateThumbnail(url));
           }
       }
    }




    return (
        <>
            <Alert/>
            <div className="library-box">
                <div className="row mt-3">
                    <form method="post" onSubmit={(e)=>onFormSubmit(e)} className="col-md-12">
                        <div className="input-group">
                            <input
                                type="text"
                                id="keyword"
                                className="form-control"
                                placeholder="Search Images & Videos"
                                onChange={(e)=>onInputChange(e)}
                                required
                            />
                            <span className="input-group-btn">
                                <button disabled={loader} className="btn btn-default" type="submit">
                                    <i className="fa fa-search" aria-hidden="true" />
                                </button>
                            </span>
                        </div>

                    </form>
                </div>
                <div className="row mt-4 mb-2">
                    <div className="col-6 col-md-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                defaultValue="option1"
                                defaultChecked
                                onChange={(e)=>setSearchData({...searchData,search_type: "image", page: 1})}
                            />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                Images
                            </label>
                        </div>

                    </div>
                    <div className="col-6 col-md-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="exampleRadios1"
                                defaultValue="image"
                                onChange={(e)=>setSearchData({...searchData,search_type: "video", page: 1})}
                            />
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                Videos
                            </label>
                        </div>
                    </div>
                </div>

                <div    id="scrollableVideoImg"
                     style={{ overflow: "auto", maxHeight: "300px", height:"auto" }}
                >

                     
                    <InfiniteScroll
                        dataLength={searchData.search_type === "image" ? imageData.data.length : videoData.data.length} //This is important field to render the next data
                        next={(e)=>fetchImgVideo(searchData.page+1)}
                        hasMore={true}
                        loader={
                            nextStatus ?
                                <h6 style={{width: "100%"}} className="m-0 text-center">we don't have more results for this keyword</h6>:
                                imageSearch === true || videoSearch === true ? <h6 style={{width: "100%"}} className="m-0 text-center"><i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6> : ''
                        }
                        scrollableTarget="scrollableVideoImg"
                    >
                                            <ul className="d-flex flex-wrap justify-content-center gap-2">
                        {
                            searchData.search_type === "image" ?
                                imageData.data.length > 0 ?
                                    imageData.data.map((item,index)=>{
                                        return(
                                           
                                            <li className="border borderr-dark" key={index}  style={{ width:"auto" , height:"100px"  ,position:"relative" , margin:"5px",overflow:"hidden" }} >
                                                <img style={{cursor: "pointer" , padding:"5px" }} height="100%" className="imagesizes"  src={item.thumb !== '' ? item.thumb : item.image}   width="100%" onClick={(e)=>selectImage(item.image,'image','',index)} />
                                                {
                                                    checkImage === index ? <span className="checked-icon" style={{
                                                        color: "rgb(22 212 137)",
                                                        top: "20px",
                                                        left: "50px",
                                                        position: "absolute"
                                                    }}><i className="far fa-check-circle"></i></span> : ''
                                                }
                                            </li>
                                           
                                        )
                                    })
                                    : ''
                                : ''
                        }


                        {
                            searchData.search_type === "video" ?
                                videoData.data.length > 0 ?
                                    videoData.data.map((item,index)=>{
                                        return(
                                            <div key={index} className="col-5 p-2 search-video" style={{overflow:'hidden' , cursor:"pointer", height:"100px"}} onClick={(e)=>selectImage(item.image,'video',item.url,index)} >
                                                <HoverVideoPlayer
                                                    videoSrc={item.url}
                                                    pausedOverlay={
                                                <img style={{cursor: "pointer",zIndex: "9999"}} className="imagesizes"  src={item.image}   width="100%" onClick={(e)=>selectImage(item.image,'video',item.url,index)} />
                                                    }
                                                    loadingOverlay={
                                                        <i style={{color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/>
                                                    }
                                                />
                                                {
                                                    checkVideo === index ? <span className="checked-icon" style={{
                                                        color: "rgb(22 212 137)",
                                                        top: "20px",
                                                        left: "50px",
                                                        position: "absolute"
                                                    }}><i className="far fa-check-circle"></i></span> : ''
                                                }
                                            </div>
                                        )
                                    })
                                    : ''
                                : ''
                        }
                          </ul>
                    </InfiniteScroll>
                    {
                         loader === true ? <i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/> : ''
                    }
                  
                </div>

            </div>
        </>
    )
}

export default MediaSearchVideo;