import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import Teamacm from './SubComponent/Teamacm'
import TeamAddc from './SubComponent/TeamAddc'
import TeamMs from './SubComponent/TeamMs'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global'
import { useSelector } from 'react-redux'

function TeamMember() {
  const brandName = useSelector(state => state.rebrand.data);

  return (
    <>
      <Helmet>
        <title>{brandName !== false ? brandName.name : appName} | Account Management </title>
      </Helmet>
      <div className="wrapper-box">
        <Navbar />
        <BannerLinebar />
        <TeamAddc />
        {/*<Teamacm/>  */}
        <TeamMs />
      </div>
    </>
  )
}

export default TeamMember
