import { combineReducers } from "redux";
import authReducer from "../reducers/authReducer";
import alert from "../reducers/alert";
import socialAccountReducer from "./socialAccountReducer";
import streamReducer from "./streamReducer"
import schedularReducer from "./schedularReducer";
import videoReducer from "./videoReducer";
import contentReducer from "./contentReducer";
import rebrandReeducer from "./rebrandReeducer";
import { WhiteLabelData } from "./WhiteLabelData";


const appReducer = combineReducers({
    auth: authReducer,
    alert: alert,
    social: socialAccountReducer,
    stream: streamReducer,
    scheduler: schedularReducer,
    videoReducer: videoReducer,
    content: contentReducer,
    rebrand: rebrandReeducer,
    wl: WhiteLabelData,
});


const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = {
            ...state,
            auth: undefined,
            alert: undefined,
            social: undefined,
            stream: undefined,
            scheduler: undefined,
            videoReducer: undefined,
            content: undefined,
            rebrand: +state.auth?.user?.isWL === 1 ? state ? state.rebrand : undefined : undefined,

            wl: undefined,
        };
    }
    return appReducer(state, action)
}

export default rootReducers;
