import React from 'react'
import AddClients from './SubComponent/AddClients'
import AddFrom from './SubComponent/AddFrom'
import Navbar from './SubComponent/Navbar'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global';
import { useSelector } from 'react-redux';

function Aaddpage() {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Client </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <AddClients client="Add" />
                <AddFrom />
            </div>
        </>
    )
}

export default Aaddpage
