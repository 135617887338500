import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { changeEmail } from "../../store/actions/authAction";
import Alert from "../alert/alert";
import { NavLink } from "react-router-dom";
import { setAlert } from '../../store/actions/alert';

function GDPRemil() {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    email: ''
  });

  const onInputChange = (e) => {

    setUser({ ...user, email: e.target.value });
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (auth.user?.email === user.email) {
      dispatch(setAlert("Email cannot be same", "danger"))

    } else {
      setLoader(true);

      dispatch(changeEmail(user, setLoader));
    }
  }

  return (
    <>
      <section className="sec-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4 d-flex align-items-center">
              <div className="sec-1-heading newline">
                <ul className="list-inline m-0 d-flex">
                  <li><NavLink to="#">Privacy</NavLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="myaccount-sec">
        <div className="container">
          <Alert />
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="personal-heading">
                <h5>Change Email</h5>
              </div>
            </div>
          </div>
          <div className="row mt-2 d-flex justify-content-center">
            <div className="col-md-12 col-lg-12">
              <form className="personal-box" method="post" onSubmit={(e) => onFormSubmit(e)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Email Address</small>
                      <input type="text" className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Enter Email Address"
                        readOnly value={auth.user.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">New Email Address</small>
                      <input type="email" className="form-control" id="exampleInputEmail1"
                        value={user.email}
                        aria-describedby="emailHelp" placeholder="Enter Email Address" required
                        name="email" onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="update">
                      <button type="submit" className="button" disabled={loader}>
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default GDPRemil
