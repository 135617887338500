import React, { useState } from 'react'
import userImage from '../../images/user.png';
import { useDispatch, useSelector } from "react-redux";
import { changeProfile, updateName } from "../../store/actions/authAction";
import Alert from "../alert/alert";
import swal from "sweetalert";

function Myapcom() {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const [loader, setLoader] = useState(false);
  const [profileStatus, setProfileStatus] = useState(false);
  const [user, setUser] = useState({
    name: auth.user.name,
    email: auth.user.email
  });

  const [userProfile, setUserProfile] = useState({
    id: auth.user.id,
    file: ''
  });

  const onFileInput = (e) => {
    if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' ||
      e.target.files[0].type === 'image/jpeg') {

      if (e.target.files[0].size < 5000000) {
        e.preventDefault();
        setProfileStatus(true);

        const form = new FormData();
        form.append('user_id', userProfile.id);
        form.append('file', e.target.files[0]);
        dispatch(changeProfile(form, setProfileStatus));
      } else {
        swal("Oops!", "Max allowed size 5MB");
      }
    } else {
      swal("Oops!", "You have Selected Invalid File Type");
    }
  }

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    dispatch(updateName(user, setLoader));
  }

  return (
    <>
      <section className="myaccount-sec">
        <div className="container">
          <Alert />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="my-account d-flex justify-content-center">
                <div className="my-img">
                  <img src={auth.user.thumbnail !== "" ? auth.user.thumbnail : auth.user.image} alt="user-img" className="img-fluid" />
                  <div className={`${profileStatus === false ? 'hide-social' : ''} my-account-loader`}>
                    <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px" }} />
                  </div>
                  <input type="file" className="custom-file-input" id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01" onChange={(e) => onFileInput(e)}
                    accept="image/*"
                  />
                </div>
                <div className="my-content">
                  <h4>{auth.user.name}</h4>
                  <a style={{ color: "#fff" }}>
                    {
                      auth.user.membership.split("__")[(auth.user.membership.split("__").length - 1)]
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="personal-heading">
                <h5>Personal Information</h5>
              </div>
            </div>
          </div>
          <div className="row mt-2 d-flex justify-content-center">
            <div className="col-md-12 col-lg-10">
              <form className="personal-box" method="post" onSubmit={(e) => onFormSubmit(e)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Name</small>
                      <input type="text" name="name" required className="form-control" id="exampleInputEmail1"
                        aria-describedby="emailHelp" placeholder="Name" onChange={(e) => onInputChange(e)}
                        value={user.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <small id="emailHelp" className="form-text text-muted">Current Email Address</small>
                      <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder value={user.email} />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="update">
                      <button type="submit" className="button" disabled={loader} >
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Myapcom
