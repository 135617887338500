import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import TrainingBanner from './SubComponent/TrainingBanner'
import TrainingImp from './SubComponent/TrainingImp'
import TrainingVideo from './SubComponent/TrainingVideo'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global'
import { useSelector } from 'react-redux'

function Training() {
  const brandName = useSelector(state => state.rebrand.data);

  return (
    <>
      <Helmet>
        <title>{brandName !== false ? brandName.name : appName} | Help & Support </title>
      </Helmet>
      <div className="wrapper-box">
        <Navbar />
        <BannerLinebar />
        {/*<TrainingBanner/>*/}
        <TrainingImp />
        <TrainingVideo />
      </div>
    </>
  )
}

export default Training
