import React from 'react'
import imac from '../../images/IMAC.png';
import mac from '../../images/MAC.png';
import imac2 from '../../images/IMAC2.png';
import mac2 from '../../images/MAC2.png';
 
function BannerPart() {
    return (
        <>
            <section className="banner-part">
                 <div className="container">
                     <div className="row">
                      <div className="col-md-6 d-flex align-items-center ">
                        <div className="banner-part-heading">
                             <span>Produce 10x more content in 1 minute</span>
                             <h4>Create a Year’s Worth of Content Hands-Free</h4>
                             <p className="mt-4" >Get 10000s of fully-customizable, engaging and ready-to-upload social media quotes, memes and video cinemagraphs for all your clients in any niche.</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                           <div className="banner-part-img">
                                <img src={imac}  alt=""  className="img-fluid"/>
                           </div>
                      </div>
                 </div>


                 <div className="row mt-5">
                      <div className="col-md-6 d-flex align-items-center mb-4">
                      <div className="banner-part-img">
                                <img src={mac}  alt=""  className="img-fluid"/>
                           </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                           <div className="banner-part-heading">
                             <span>Auto-Find Clients Looking for your Services</span>
                             <h4>Auto-Find Clients Looking for your Services</h4>
                             <p className="mt-4" >Get started in just a few clicks. Simply type in any keyword related to your niche and instantly get a live contact list of businesses that need your marketing agency services.</p>
                        </div>
                      </div>
                 </div>

                 <div className="row mt-5">
                      <div className="col-md-6 d-flex align-items-center ">
                        <div className="banner-part-heading">
                             <span>Produce 10x more content in 1 minute</span>
                             <h4>Create a Year’s Worth of Content Hands-Free</h4>
                             <p className="mt-4" >Get 10000s of fully-customizable, engaging and ready-to-upload social media quotes, memes and video cinemagraphs for all your clients in any niche.</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                           <div className="banner-part-img">
                                <img src={imac2}  alt=""  className="img-fluid"/>
                           </div>
                      </div>
                 </div>

                 <div className="row mt-5">
                      <div className="col-md-6 d-flex align-items-center mb-4">
                      <div className="banner-part-img">
                                <img src={mac2}  alt=""  className="img-fluid"/>
                           </div>
                      </div>
                      <div className="col-md-6 d-flex align-items-center">
                           <div className="banner-part-heading">
                             <span>Get organized with a powerful dashboard</span>
                             <h4>Take on 100s of Clients Effortlessly</h4>
                             <p className="mt-4">Manage all your clients’ content and calendar in one place, no matter how many social media accounts they have. Easily integrate and post across Facebook, Twitter, LinkedIn and more.</p>
                         </div>
                      </div>
                 </div>


                </div>
               
            </section>
        </>
    )
}

export default BannerPart
