import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./SubComponent/Navbar";
import Alert from "./alert/alert";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteClientAccount,
} from "../store/actions/authAction";
import axios from "axios";
import { appName, baseURL } from "../global/global";
import { setAlert } from "../store/actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { NavLink } from "react-router-dom";


const ResellerAccount = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [resellerAccount, setResellerAccount] = useState({ data: [] })
    const brandName = useSelector(state => state.rebrand.data);

    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');
    const [deleteId, setDeleteId] = useState({
        id: null
    });

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        name: null,
        email: null,
        password: null
    });

    const [clientDetails, setClientDetails] = useState({
        name: "",
        password: "",
        id: ""
    });

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onUpdateInputChange = (e) => {
        setClientDetails({ ...clientDetails, [e.target.name]: e.target.value })
    }

    const onFormUpdateSubmit = (e, id) => {
        setLoader(true);
        e.preventDefault();

        axios({
            method: 'POST',
            url: `${baseURL}reseller`,
            data: JSON.stringify({ id: id, name: clientDetails.name, password: clientDetails.password, email: "" }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                fetchResellerAccount();
                dispatch(setAlert('Reseller account created successfully.', "success"));

            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }
            setLoader(false);

        }).catch(error => {
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}reseller`,
            data: user,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                fetchResellerAccount();
                dispatch(setAlert(res.data.message, "success"));

            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }
            setLoader(false);

        }).catch(error => {
            setLoader(false);
        })

    }


    const onCancel = () => {
        setShowDelete(false);
    }

    const onConfirm = async () => {
        setButton('Please wait...');

        await axios({
            method: 'POST',
            url: `${baseURL}delete-reseller-accounts`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                fetchResellerAccount();
                dispatch(setAlert(res.data.message, "success"));

            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }

        }).catch(error => {
        })
        setShowDelete(false);
        setButton('Delete');
    }

    const onButtonClick = (e, id) => {
        e.preventDefault();

        setDeleteId({ id: id });
        setShowDelete(true);
    }

    const fetchResellerAccount = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-reseller-accounts`,
            data: user,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                console.log(res.data);
                setResellerAccount({ ...resellerAccount, data: res.data.accounts })
            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }

        }).catch(error => {
        })
    }


    useEffect(() => {
        return () => {
            setResellerAccount({ ...resellerAccount, data: [] })
        }
    }, []);


    useEffect(() => {
        fetchResellerAccount();
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Reseller Account </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <section className="sec-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 d-flex align-items-center">
                                <div className="sec-1-heading newline">
                                    <ul className="list-inline m-0 d-flex">
                                        <li><NavLink to="#">Reseller</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="myaccount-sec">
                    <div className="container">
                        <Alert />
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <div className="personal-heading">
                                    <h5>Create Reseller Account</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 d-flex justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <form className="personal-box" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                    <div className="row">
                                        {/*<div className="col-md-4">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <small id="emailHelp" className="form-text text-muted">Account Type</small>*/}
                                        {/*        <select className="form-control" name="type" required onChange={(e)=>onInputChange(e)}>*/}
                                        {/*            <option value="reseller">Reseller</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <small id="emailHelp" className="form-text text-muted">Account Name</small>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Name" required
                                                    name="name" onChange={(e) => onInputChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <small id="emailHelp" className="form-text text-muted">Account Email</small>
                                                <input type="email" className="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Email" required
                                                    name="email" onChange={(e) => onInputChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <small id="emailHelp" className="form-text text-muted">Account Password</small>
                                                <input type="password" className="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Password" required
                                                    name="password" onChange={(e) => onInputChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <div className="update">
                                                <button type="submit" className="button" disabled={loader}>
                                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<Teamacm/>  */}
                <section className="team-member-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="personal-heading">
                                    <h5>Reseller Account Management</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 d-flex justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <div className="personal-box">
                                    <section className="user-data" style={{ padding: "0px" }}>
                                        <div className="container" style={{ padding: "0px" }}>
                                            <div className="row">
                                                <div className="col-md-12 mx-auto">
                                                    <table className="table table-border table-responsive-lg">
                                                        <thead className="theadbgcolor text-white">
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email Address</th>
                                                                <th scope="col">Account Type</th>
                                                                <th scope="col">Created</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tbody-bg">
                                                            {
                                                                resellerAccount.data.length > 0 ?

                                                                    resellerAccount.data.map((client) => {
                                                                        return (
                                                                            <>
                                                                                <tr className="accordion-toggle collapsed" id={`accordion${client.id}`} key={client.id}>
                                                                                    <td className="td-br"><h6 title={client.name} >{client.name}</h6></td>
                                                                                    <td className="text-overflow td-br"><h6 title={client.email}>{client.email}</h6></td>
                                                                                    <td className="td-br">Reseller Account</td>
                                                                                    <td className="td-br">{client.created}</td>
                                                                                    <td className="expand-button">
                                                                                        <a className="action-icon delete-icon remove mb-1 pl-xl-2 mt-md-1 collapsed"
                                                                                            title="Edit" data-toggle="collapse" data-parent={`accordion${client.id}`}
                                                                                            href={`#client_${client.id}`} onClick={(e) => setClientDetails({ ...clientDetails, name: client.name, id: client.id })} aria-expanded="false">
                                                                                            <i className="fas fa-edit"></i>
                                                                                        </a>

                                                                                        <a className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1" style={{ cursor: "pointer", color: "#f05359" }}
                                                                                            onClick={(e) => onButtonClick(e, client.id)} to="delete">
                                                                                            <i className="fas fa-trash-alt"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr className="hide-table-padding">
                                                                                    <td colSpan="12" style={{ padding: "0px" }}>
                                                                                        <div id={`client_${client.id}`} className="p-3 collapse in"
                                                                                            style={{ width: "100%" }}>
                                                                                            <div className="col-12">
                                                                                                <p className="text-white"></p>
                                                                                                <div className="mt-2 mb-0">
                                                                                                    <h6 className=" text-left mb-4 mt-3"
                                                                                                        style={{ marginLeft: "-10px" }}>Edit Reseller</h6>
                                                                                                    <div>
                                                                                                        <form method="post" onSubmit={(e) => onFormUpdateSubmit(e, client.id)}>
                                                                                                            <div
                                                                                                                className="row d-flex justify-content-center">
                                                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                                                    <div
                                                                                                                        className="form-group form-group-mb">
                                                                                                                        <label className="input-label-pos">Name</label>
                                                                                                                        <input className="form-control input-dark-accnt personal-info w-100"
                                                                                                                            type="text" name="name" defaultValue={client.name}
                                                                                                                            onChange={(e) => onUpdateInputChange(e)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                                                    <div className="form-group form-group-mb">
                                                                                                                        <label className="input-label-pos">
                                                                                                                            Email Address
                                                                                                                        </label>
                                                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                                                            type="email" name="email" placeholder="Email Address"
                                                                                                                            readOnly value={client.email} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                                                    <div className="form-group form-group-mb">
                                                                                                                        <label className="input-label-pos">
                                                                                                                            Password <small>(leave blank for same password)</small>
                                                                                                                        </label>
                                                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                                                            type="password" name="password" placeholder=""
                                                                                                                            defaultValue={''} onChange={(e) => onUpdateInputChange(e)} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                                                                                    <div
                                                                                                                        className="form-group form-group-mb">
                                                                                                                        <label
                                                                                                                            className="input-label-pos">Account
                                                                                                                            Type
                                                                                                                        </label>
                                                                                                                        <input className="form-control input-dark-accnt personal-info"
                                                                                                                            type="text" name="Account Type" placeholder="Account Type"
                                                                                                                            readOnly value="Reseller" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-12 cancel-update text-center mt-5 mb-5">
                                                                                                                <a style={{ display: "inline-block", marginRight: "10px", textDecoration: "none", color: "#fff" }} type="button" className="btn-submit"
                                                                                                                    data-toggle="collapse" data-parent={`accordion${client.id}`}
                                                                                                                    href={`#client_${client.id}`} aria-expanded="false"
                                                                                                                >Cancel</a>
                                                                                                                <button style={{ display: "inline-block", textTransform: "capitalize" }} type="submit" className="button" disabled={loader}>
                                                                                                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}Update
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </form>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>

                                                                            </>
                                                                        )
                                                                    })

                                                                    :
                                                                    (
                                                                        <tr>
                                                                            <td colSpan="5" align="center">No Records Found</td>
                                                                        </tr>
                                                                    )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this reseller account
            </SweetAlert>
        </>
    )
}

export default ResellerAccount;