import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import SDetailshead from './SubComponent/SDetailshead'
import SDetailsname from './SubComponent/SDetailsname'
import SDetailsTable from './SubComponent/SDetailsTable'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global'
import { useSelector } from 'react-redux'

function ScheduleDetails(props) {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Schedule Details </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                {/*<SDetailshead/>*/}
                {/*<SDetailsname id={props}/>*/}
                <SDetailsTable id={props} />
            </div>
        </>
    )
}

export default ScheduleDetails
