import React from 'react'
import footerimg from '..//../images/footerimg.png';
import { useSelector } from 'react-redux';

function Footer() {
  const rebrandData = useSelector(state => state.rebrand.data);
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 d-flex flex-column justify-content-center p-0">
              <div className="footer-menu">
                <ul className="list-inline  d-flex justify-content-center gap-2 m-0 p-0">
                  <li><a href="https://reelapps.io/terms.html" target="_blank">Terms &amp; Conditions</a></li>
                  <li><a href="https://reelapps.io/privacy.html" target="_blank">Privacy Policy</a></li>
                  <li><a href="https://support.vineasx.com/" target="_blank">Support</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center text-center">
              <div className="footer-copyright text-white">
                <span>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="blank">VineaSX Solutions LLC.</a> Developed and Maintained by
                  <a href="https://vega6.com/" target="_blank"> Vega6</a></span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-img">
                {
                  rebrandData.data !== false ?
                    <a href="https://reelapps.io/" target="_blank">
                      <img src={rebrandData.logo} className="img-fluid ml-auto logo-image-css" alt="logo" width="180px" />
                    </a> :
                    <a href="https://reelapps.io/" target="_blank"><img src={footerimg} alt={'logo'} /></a>
                }
                {/* <a href="https://reelapps.io/" target="_blank"><img src={footerimg} alt className="img-fluid" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
