import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";

const QuoteImage = ({addQuotesData}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [quote, setQuote] = useState({data: []});
    const [countErr, setCountErr] = useState(false);
    const [searchData, setSearchData] = useState({
        keyword: "",
        page: 1,
        search_type: "quote"
    });

    const onInputChange = (e) => {
        setSearchData({...searchData, keyword: e.target.value, page: 1});

        setQuote({...quote, data: []});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        searchQuotes();
    }

    const searchQuotes = () => {
        setLoader(true);
        axios({
            method: 'POST',
            url: `${baseURL}search-media`,
            data: searchData,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            console.log(res.data);
            if(res.data.status === true){
                setQuote({...quote, data: quote.data.concat(res.data.data)});

                setSearchData({...searchData, page: searchData.page+1});
            }else{
                setCountErr(true);
            }
            setLoader(false);
        }).catch(err => {
            setCountErr(true);
            setLoader(false);
        })
    }

    const addQuote = (text) => {
        addQuotesData(text,'body')
    }

    useEffect(()=>{
        searchQuotes();
    },[])


    return(
        <>
            <div className="library-box">
                <div className="row mt-3 mb-3">
                    <form method="post" onSubmit={(e)=>onFormSubmit(e)} className="col-md-11 mx-auto">
                        <div className="input-group">
                            <input
                                type="text"
                                id="keyword"
                                className="form-control"
                                placeholder="Quote Search"
                                onChange={(e)=>onInputChange(e)}
                                required
                            />
                            <span className="input-group-btn">
                                <button disabled={loader} className="btn btn-default" type="submit">
                                    <i className="fa fa-search" aria-hidden="true" />
                                </button>
                            </span>
                        </div>

                    </form>
                </div>

                <div className="row mb-3">
                    <div id="scrollableQuote" style={{ overflowY: "scroll", height: "300px" }} className="audio-library-box mt-3">
                        <InfiniteScroll
                            dataLength={quote.data.length}
                            next={(e)=>searchQuotes()}
                            hasMore={true}
                            loader={
                                countErr ?
                                    <h6 style={{width: "100%"}} className="m-0 text-center">we don't have more results</h6>:
                                    loader ? <h6 style={{width: "100%"}} className="m-0 text-center"><i style={{fontSize: "40px", color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></h6>: ''
                            }
                            scrollableTarget="scrollableQuote"
                        >
                            {
                                quote.data.length > 0 ?
                                    quote.data.map((item, index)=>{
                                        return(
                                            <div key={index} className="col-md-12 mb-2">
                                                <div className="card ml-3" style={{padding: "10px"}}>
                                                    <div className="card-body p-0">
                                                        <div className="row justify-content-between">
                                                            <div className="col-md-9 d-flex align-items-center">
                                                                <div className="music-content">
                                                                    <div className="music-name music-align">
                                                                        {item.quote.replaceAll("&#8217;","'")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3 d-flex align-items-center">
                                                                <button
                                                                    onClick={(e)=>addQuote(item.quote)}
                                                                    type="button"
                                                                    className="quote-button">
                                                                    Use
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </InfiniteScroll>
                    </div>
                </div>

            </div>
        </>
    )
}


export default QuoteImage;