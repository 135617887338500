import React from 'react';
import Navbar from "./SubComponent/Navbar";
import ScheduleHead from "./SubComponent/ScheduleHead";
import ScheduleCalender from "./SubComponent/ScheduleCalender";
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global';
import { useSelector } from 'react-redux';

const Scheduler = () => {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Scheduler </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <ScheduleHead />
                <ScheduleCalender />
            </div>
        </>
    )
}

export default Scheduler;