import React from 'react'
import play from '../../images/IMG2.png';


function DeshJoin() {
    return (
        <>
            <section className="join-sec">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 d-flex justify-content-center"> 
        <div className="join-vidoe d-flex justify-content-center align-items-center">
          <div className="play-icon">
            <img src={play} alt className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center">
        <div className="join-sec-content pl-4">
          <h6>AGENCYREEL LESSON #1</h6>
          <h4>Join Us for our LIVE Webinar.</h4>
          <p>Join us for a private training that will walk you through how to 
            use AgencyReel to help you create and sell videos for maximum
            engagement, views and profits. This training or upgrade will 
            not be made available afterwards. Sign up and make sure to 
            show up on this live training with all your questions. </p>
          {/*<div className="sign-now mt-4">*/}
          {/*  <a target="_blank" href="https://abhineos.com/training" className="button">Sign Up Now  <span style={{marginLeft:"10px"}} ><i class="fas fa-arrow-right"></i></span> </a>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  </div>
</section>

        </>
    )
}

export default DeshJoin
