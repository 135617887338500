import React, { useEffect, useState } from "react";
import downloads from "../../images/downloads.png";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import {
    addMusicLayer,
    updateAudioLayer,
    updateTtsGen,
    updateTtsGenStatus,
    updateTtxText
} from "../../store/actions/videoAction";
import { setAlert } from "../../store/actions/alert";
import Alert from "../alert/alert";
import InfiniteScroll from "react-infinite-scroll-component";
import swal from "sweetalert";

let audio = false;
const AudioVideo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const textStateData = useSelector(state => state.videoReducer);

    const [checkTtsAll, setCheckTtsAll] = useState(false);

    // Search Selected Index
    let selectedIndex = textStateData.tiles.findIndex(({ is_selected }) => is_selected === "true");

    let selectedLayer = textStateData.tiles.find(({ is_selected }) => is_selected === "true");

    const [ttsStatus, setTtsStatus] = useState(false);
    const [ttsGenStatus, setTtsGenStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState({ data: [] });
    const [uploadAudio, setUploadAudio] = useState({ data: [] });
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [ttsData, setTtsData] = useState({
        text: '',
        language_id: '',
        voice_id: ''
    });
    const [generatedTts, setGeneratedTts] = useState({
        status: true,
        url: ''
    })

    const [currentUrl, setCurrentUrl] = useState('');
    const [lastAudio, setLastAudio] = useState('');

    const [pageNum, setPageNum] = useState(1);

    const addMusic = async (url) => {

        let data = {
            status: true,
            url: url
        }

        await dispatch(addMusicLayer(data));
        dispatch(setAlert('Audio added successfully', "success"));

    }

    const searchAudio = (page = pageNum) => {
        console.log('called >>>', pageNum)
        axios({
            method: 'POST',
            url: `${baseURL}search-media`,
            data: JSON.stringify({ search_type: "music", keyword: "", page: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {

                setAudioData({ ...audioData, data: audioData.data.concat(res.data.data) });
            }
            setPageNum((pageNum + 1));

        }).catch(error => {
            console.log(error);
        })
    }

    const onAudioUpload = (e) => {

        if (e.target.files[0].type === 'audio/mpeg' || e.target.files[0].type === 'audio/wav') {
            if (e.target.files[0].size < 10000000) {
                setLoader(true);

                let formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('file_type', 'music');

                axios({
                    method: 'POST',
                    url: `${baseURL}upload-file`,
                    data: formData,
                    headers: { 'Authorization': auth.token }
                }).then(res => {

                    if (res.data.status === true) {
                        setUploadAudio({ ...uploadAudio, data: uploadAudio.data.concat(res.data.data) });
                    } else {
                        dispatch(setAlert(res.data.message, "danger"));
                    }
                    setLoader(false);

                }).catch(error => {
                    console.log(error);
                })
            } else {
                swal("Oops!", "Max Uploaded Size for music 10MB").then(r => console.log(r));
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type ");
        }
    }

    const searchPollyLanguages = () => {
        axios({
            method: 'GET',
            url: `${baseURL}get-polly-languages`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                setPollyLanguages({ ...pollyLanguages, data: res.data.data });
            } else {
                dispatch(setAlert(res.data.message, "danger"));
            }

        }).catch(error => {
            console.log(error);
        })
    }

    const searchPollyVoices = (value) => {

        let formData = new FormData();
        formData.append('lang_code', value);

        axios({
            method: 'POST',
            url: `${baseURL}get-artists`,
            data: formData,
            headers: { 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                setPollyVoices({ ...pollyVoices, data: res.data.data });
                setTtsData({ ...ttsData, language_id: value, voice_id: res.data.data[0].voice_id });
            }

        }).catch(error => {
        })
    }

    const getLanguage = async (e) => {
        setTtsStatus(false);
        setTtsGenStatus(false);

        dispatch(updateTtsGenStatus(selectedIndex)); // for update tts status

        await searchPollyVoices(e.target.value);
        // setTtsData({...ttsData, language_id: e.target.value});
    }

    const getVoices = (e) => {
        setTtsStatus(false);
        setTtsGenStatus(false);

        dispatch(updateTtsGenStatus(selectedIndex)); // for update tts status

        setTtsData({ ...ttsData, voice_id: e.target.value });
    }

    const onTtsInput = (e) => {
        setTtsStatus(false);
        setTtsGenStatus(false);

        setTtsData({ ...ttsData, text: e.target.value });

        dispatch(updateTtsGenStatus(selectedIndex)); // for update tts status

        dispatch(updateTtxText(e.target.value, selectedIndex))
    }

    const generateTts = (e) => {
        if (selectedLayer.tts_text !== '' || selectedLayer.text !== '') {

            setLoader(true);
            axios({
                method: 'POST',
                url: `${baseURL}generate-tts`,
                data: JSON.stringify({
                    text: selectedLayer.tts_text !== '' ? selectedLayer.tts_text : selectedLayer.text,
                    language_id: ttsData.language_id,
                    voice_id: ttsData.voice_id
                }),
                headers: { 'Authorization': auth.token }
            }).then(res => {

                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, 'success'));
                    setGeneratedTts({ ...generatedTts, url: res.data.file_url });

                    dispatch(updateTtsGen(res.data.file_url, selectedIndex));

                    setTtsStatus(true);
                    setTtsGenStatus(true);
                    setLoader(false);
                } else {
                    dispatch(setAlert(res.data.message, 'danger'));
                    setLoader(false);
                }

            }).catch(error => {
                console.log(error);
            })
        } else {
            dispatch(setAlert('Please add text before generate TTS', 'danger'));
        }
    }

    const saveAllTts = async (e) => {

        if (checkTtsAll === false) {
            if (generatedTts.audio !== '') {
                // dispatch(addMusicLayer(generatedTts));
                textStateData.tiles.map((tile, index) => {
                    generateAllTts(tile.text, index)
                })
            } else {
                swal("Oops!", "You don't have audio");
            }
            setCheckTtsAll(true);
        } else {
            textStateData.tiles.map((tile, index) => {
                dispatch(updateAudioLayer(index, ''));
            })
            setCheckTtsAll(false);
        }
    }

    const generateAllTts = async (text, index) => {
        if (text !== '') {
            await axios({
                method: 'POST',
                url: `${baseURL}generate-tts`,
                data: JSON.stringify({ text: text, language_id: ttsData.language_id, voice_id: ttsData.voice_id }),
                headers: { 'Authorization': auth.token }
            }).then(res => {
                console.log(res);
                if (res.data.status === true) {
                    dispatch(updateAudioLayer(index, res.data.file_url));
                } else {
                    dispatch(setAlert(res.data.data, 'danger'));
                }
            }).catch(err => {
                console.log(err);
            })
        } else {
            swal("Oops!", "You don't have text for slide " + (index + 1));
        }
    }

    const saveUse = (e) => {
        dispatch(updateAudioLayer(selectedIndex, selectedLayer.tts_gen));
    }

    useEffect(() => {
        searchAudio();
        searchPollyLanguages();
        searchPollyVoices('en-US');
    }, []);



    const play = (e, url, index = '') => {

        setCurrentUrl(url);
        setLastAudio(index);

        if (lastAudio !== '') {
            let pause = document.getElementById(`audio-${lastAudio}`);
            pause.classList.remove('fa-pause-circle')
            pause.classList.add('fa-play-circle')
        }

        if (audio !== false) {
            audio.pause();
            e.target.classList.remove("fa-pause-circle")
            e.target.classList.add("fa-play-circle")

        }

        if (currentUrl === url) {
            audio.pause();
            setCurrentUrl('');
            e.target.classList.remove("fa-pause-circle")
            e.target.classList.add("fa-play-circle")
        } else {
            audio = new Audio(url);

            e.target.classList.remove("far")
            e.target.classList.remove("fa-play-circle")
            e.target.classList.add("fa");
            e.target.classList.add("fa-spinner");
            e.target.classList.add("fa-spin");

            audio.play().then((res) => {
                e.target.classList.remove("fa");
                e.target.classList.remove("fa-spinner");
                e.target.classList.remove("fa-spin");
                e.target.classList.remove("fa-play-circle")
                e.target.classList.add("far")
                e.target.classList.add("fa-pause-circle")
            }).catch(() => {
            })

            audio.onended = function () {
                e.target.classList.remove("fa-pause-circle")
                e.target.classList.add("fa-play-circle")
            }
        }
    }


    useEffect(() => {
        if (audio !== false) {
            audio.pause();
            let pause = document.getElementById(`ttsIcon`);
            pause.classList.remove('fa-pause-circle')
            pause.classList.add('fa-play-circle')
        }

        return () => {
            if (audio !== false) {
                audio.pause();
                console.log("unmount audio >>");
            }
        }

    }, [selectedIndex])

    return (
        <>
            <Alert />
            <div className="create-video-box mt-5">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <a className="nav-link active button" id="v-pills-audiolibrary-tab"
                                        data-toggle="pill" href="#v-pills-audiolibrary" role="tab"
                                        aria-controls="v-pills-audiolibrary" aria-selected="true">Audio Library</a>
                                    <a className="nav-link button" id="v-pills-textspeech-tab" data-toggle="pill"
                                        href="#v-pills-textspeech" role="tab" aria-controls="v-pills-textspeech"
                                        aria-selected="false">Text to Speech</a>
                                    <a className="nav-link button" id="v-pills-uploadmusic-tab" data-toggle="pill"
                                        href="#v-pills-uploadmusic" role="tab" aria-controls="v-pills-uploadmusic"
                                        aria-selected="false">Upload Audio</a>
                                </div>
                            </div>
                            <div className="col-md-8 linebar">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane  fade show active" id="v-pills-audiolibrary" role="tabpanel"
                                        aria-labelledby="v-pills-audiolibrary-tab">
                                        <div className="audio-line-box"></div>
                                        <div id="scrollableAudio" style={{ overflowY: "auto", height: "300px" }} className="audio-library-box mt-3">
                                            <InfiniteScroll
                                                dataLength={audioData.data.length}
                                                next={(e) => searchAudio(pageNum + 1)}
                                                hasMore={true}
                                                loader={<h6 style={{ width: "100%" }} className="m-0 text-center"><i style={{ fontSize: "40px", color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></h6>}
                                                scrollableTarget="scrollableAudio"
                                            >
                                                {
                                                    audioData.data.length ?
                                                        audioData.data.map((item, index) => {
                                                            return (
                                                                <div key={index} className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="card ml-3">
                                                                            <div className="card-body p-0">
                                                                                <div className="row">

                                                                                    <div className="col-md-2 col-2 d-flex align-items-center">
                                                                                        <div className="border-left-design" />
                                                                                        <button type="button" className="playaudio">
                                                                                            <span><i id={`audio-${index}`} className="far fa-play-circle" onClick={(e) => play(e, item.url, index)} /></span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-md-7 col-7 d-flex align-items-center">
                                                                                        <div className="music-content">
                                                                                            <div className="music-name music-align">
                                                                                                {item.title}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-2 col-2 d-flex align-items-center">
                                                                                        <button
                                                                                            onClick={(e) => addMusic(item.url)}
                                                                                            type="button"
                                                                                            className="btn-add-quote float-right">
                                                                                            Use
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </InfiniteScroll>

                                        </div>
                                    </div>
                                    <div className="tab-pane  fade" id="v-pills-textspeech" role="tabpanel"
                                        aria-labelledby="v-pills-textspeech-tab">
                                        <div className="textspeech-box ml-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group text-left">
                                                        <label htmlFor="exampleInputEmail1">Slide</label>
                                                        <input type="number" className="form-control" value={(selectedIndex + 1)} readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group text-left">
                                                        <label >Language</label>
                                                        <select className="form-control" onChange={(e) => getLanguage(e)}>
                                                            {
                                                                pollyLanguages.data.length > 0 ?
                                                                    pollyLanguages.data.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.code}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group text-left">
                                                        <label>Voice</label>
                                                        <select className="form-control" onChange={(e) => getVoices(e)}>
                                                            {
                                                                pollyVoices.data.length > 0 ?
                                                                    pollyVoices.data.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.voice_id}>{item.voice_id} ({item.gender})</option>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">

                                                        <textarea
                                                            className="form-control"
                                                            rows={5}
                                                            onChange={(e) => onTtsInput(e)}
                                                            value={selectedLayer?.tts_text !== '' ? selectedLayer.tts_text : selectedLayer.text}
                                                        >
                                                            {selectedLayer?.tts_text !== '' ? selectedLayer.tts_text : selectedLayer.text}
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row  genrate-play-save">
                                                <div className="col-md-6 text-left ">
                                                    {
                                                        selectedLayer.tts_gen === "" ? <button onClick={(e) => generateTts(e)} type="button"
                                                            disabled={loader}
                                                            className="button">
                                                            {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Generate
                                                        </button>
                                                            : ''
                                                    }
                                                    {
                                                        selectedLayer.tts_gen !== "" ? <button type="button" className="button" onClick={(e) => saveUse(e)}>Save & Use</button> : ''
                                                    }
                                                </div>
                                                <div className="col-md-4">

                                                    {
                                                        selectedLayer.tts_gen !== "" ?
                                                            <button
                                                                disabled={selectedLayer.tts_gen !== "" ? false : true}
                                                                type="button" className="button"
                                                                title="play"
                                                            >
                                                                <i id="ttsIcon" className="far fa-play-circle" onClick={(e) => play(e, selectedLayer.tts_gen)} />
                                                            </button>
                                                            : selectedLayer.audio !== "" ?
                                                                <button type="button" className="button" title="play">
                                                                    <i id="ttsIcon" className="far fa-play-circle" onClick={(e) => play(e, selectedLayer.audio)} />
                                                                </button> : <i id="ttsIcon" style={{ display: "none" }}></i>
                                                    }

                                                </div>


                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <div className="form-check text-left">
                                                        <input className="form-check-input" type="checkbox" onChange={(e) => saveAllTts(e)} />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Save for all
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="v-pills-uploadmusic"
                                        role="tabpanel" aria-labelledby="v-pills-uploadmusic-tab">
                                        <div className="upload-box-video">
                                            <div className="row">
                                                <div className="col-md-8 mx-auto upload-border p-3">
                                                    <div className="custom-file">
                                                        <input type="file"
                                                            onChange={(e) => onAudioUpload(e)}
                                                            className="custom-file-input-audio"
                                                            id="file"
                                                            accept="audio/*"

                                                        />

                                                    </div>

                                                    <span><img src={downloads} alt="download" /></span>
                                                    <p className="mt-4 upload-font">MP3 up to 10MB is allowed</p>
                                                </div>
                                            </div>

                                            <div className="row" style={{ display: "block", padding: "0 30px" }}>
                                                {
                                                    loader === true ? <i style={{ fontSize: "40px", color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /> : ''
                                                }
                                                <div className="audio-library-box mt-3">
                                                    {
                                                        uploadAudio.data.length > 0 ?
                                                            uploadAudio.data.map((item, index) => {
                                                                return (<>
                                                                    <div className="row mb-1">
                                                                        <div key={index} className="col-md-12">
                                                                            <div className="card ml-3">
                                                                                <div className="card-body p-0">
                                                                                    <div className="row">
                                                                                        <div className="col-2 d-flex align-items-center">
                                                                                            <div className="border-left-design" />
                                                                                            <button type="button" className="playaudio" onClick={(e) => play(e, item, index)}>
                                                                                                <i className="fas fa-play-circle"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="col-6 d-flex align-items-center">
                                                                                            <div className="music-content">
                                                                                                <div className="music-name music-align">
                                                                                                    Uploaded File
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2 d-flex align-items-center">
                                                                                            <button
                                                                                                onClick={(e) => addMusic(item)}
                                                                                                type="button"
                                                                                                className="btn-add-quote float-right">
                                                                                                Use
                                                                                            </button>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>

                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    )
}

export default AudioVideo;