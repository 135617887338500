import React, { useEffect, useState } from 'react';
import '../report.css';
import Navbar from "./SubComponent/Navbar";
import axios from "axios";
import { appName, baseURL } from "../global/global";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import redditImg from "../images/reddit_new.png"
import { setAlert } from "../store/actions/alert";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    geocodeByAddress,
    getLatLng
} from "react-google-places-autocomplete";

import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

import swal from "sweetalert";
import GoogleTrend from "./SubComponent/GoogleTrend";
import Select from "react-select"
import BannerLinebar from "./SubComponent/BannerLinebar";
import { NavLink } from "react-router-dom";

const Trends = () => {
    const brandName = useSelector(state => state.rebrand.data);

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [trendState, setTrendState] = useState({
        social: 'reddit',
        connection: null,
        type: 'relevance',
        keyword: null,
        location: null
    });

    const [googleKeys, setGoogleKeys] = useState('AIzaSyBoF_FL4_xCZqi2jHVPEubYgG7n-rln3n8');

    const [youtubeLoader, setYoutubeLoader] = useState(false);
    const [redditResponse, setRedditResponse] = useState([]);
    const [youtubeResponse, setYoutubeResponse] = useState([]);
    const [twitterResponse, setTwitterResponse] = useState([]);
    const [googleResponse, setGoogleResponse] = useState([]);
    const [twitterLocation, setTwitterLocations] = useState([]);
    const [googleLocation, setGoogleLocations] = useState([]);
    const [loader, setLoader] = useState(false);
    const [countryCode, setCountryCode] = useState('');

    const [googleLoc, setGoogleLoc] = useState('');
    const [twitterLoc, setTwitterLoc] = useState('');
    const [redditToken, setRedditToken] = useState('');
    const [redditLoad, setRedditLoad] = useState(false);

    const [youtubeLoad, setYoutubeLoad] = useState(false);
    const [youtubeToken, setYoutubeToken] = useState('');

    const [collapseGoogle, setCollapseGoogle] = useState('Show More');

    const redditArr = ['self', 'default', 'image'];

    // Google Places
    const [address, setAddress] = useState();
    const [addressObj, setAddressObj] = useState();
    const [latLng, setLatLng] = useState({ lat: '', long: '' });
    const [commonLic, setCommonLic] = useState(false);
    const [timezone, setTimezone] = useState('-60');


    const onInputChange = (e) => {
        setTrendState({ ...trendState, [e.target.name]: e.target.value });

        if (e.target.name === "keyword") {
            setYoutubeResponse([]);
            setRedditResponse([]);
        }
    }

    const onInputTimezone = (e) => {
        setTimezone(e.target.value);
    }

    const onLocationChange = (e) => {
        setGoogleLoc(e);
        setTrendState({ ...trendState, location: e.value });
    }

    const onLocationTwitterChange = (e) => {
        setTwitterLoc(e);
        setTrendState({ ...trendState, location: e.value });

    }

    const onCheckCommon = (e) => {
        if (commonLic === true) {
            setCommonLic(false);
        } else {
            setCommonLic(true);
        }
    }

    const searchTopic = (e) => {
        e.preventDefault();
        setLoader(true);

        if (trendState.social === 'reddit') {
            searchRedditSocial();
        }
        else if (trendState.social === 'youtube') {
            searchYoutubeSocial()
        }
        else if (trendState.social === 'twitter') {
            searchTwitterSocial()
        }
        else if (trendState.social === 'google') {
            searchGoogleSocial();
        }
    }

    const searchGoogleSocial = async () => {
        if (trendState.location !== '') {
            let options = {
                headers: {
                    'Authorization': auth.token
                }
            }

            let data = {
                "country": trendState.location,
                "timezone": timezone
            }

            await axios.post(`${baseURL}google-trends`, data, options).then(res => {
                let arr = [];
                if (res.data.status === true) {
                    if (res.data.data.trendingSearchesDays.length > 0) {
                        res.data.data.trendingSearchesDays.forEach(ele => {
                            arr = [...arr, ...ele.trendingSearches];
                        })
                    }
                    setGoogleResponse(arr);
                } else {
                    setGoogleResponse([]);
                }
                setLoader(false);
            })
        } else {
            setLoader(false);
            swal("Oops!", "Please select location first")

        }
    }

    const searchRedditSocial = async () => {
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);
        setRedditResponse(jsonData.data.children);
        setLoader(false);
    }

    const loadMoreReddit = async () => {
        setRedditLoad(true);
        let response = await fetch(`https://www.reddit.com/search.json?q=${trendState.keyword}&sort=${trendState.type}&show=all&after=${redditToken}`);

        let jsonData = await response.json();
        setRedditToken(jsonData.data.after);

        let arr = [...redditResponse];
        setRedditResponse(arr.concat(jsonData.data.children));
        setRedditLoad(false);
    }

    const downloadYoutube = async (id) => {
        setYoutubeLoader(true);
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }
        await axios.get(`${baseURL}download-youtube-video?url=https://www.youtube.com/watch?v=${id}`, options).then(res => {
            if (res.data.status === true) {
                console.log(res.data);
                window.location = 'https://backend.agencyreel.io/download-file?url=' + res.data.url;
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setYoutubeLoader(false);
        })
    }

    const searchTwitterSocial = async () => {
        if (latLng.lat !== '' && latLng.long !== '') {
            let options = {
                headers: {
                    'Authorization': auth.token
                }
            }

            await axios.post(`${baseURL}search-twitter`, latLng, options).then(res => {
                if (res.data.status === true) {
                    setTwitterResponse(res.data.data);
                }
                setLoader(false);
            })
        } else {
            setLoader(false);
            swal("Oops!", "Please select location first")
        }
    }

    const searchGoogleLocation = async () => {
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}geo-locations`, {}, options).then(res => {
            if (res.data.status === true) {
                // setTwitterLocations(res.data.data);
                let tempArr = res.data.data.map((item, index) => {
                    return (
                        { label: item.name, value: item.code }
                    )
                })
                setTwitterLocations(tempArr)
                setGoogleLocations(tempArr);

            }
            setLoader(false);
        })
    }

    const searchYoutubeSocial = async () => {
        setYoutubeLoad(true);

        let options = {
            headers: {
                'Authorization': auth.token
            }
        }
        let data = {
            keyword: trendState.keyword,
            no_of_videos: 10,
            next_page_token: youtubeToken,
            license: commonLic
        }

        await axios.post(`${baseURL}search-youtube`, data, options).then(res => {
            if (res.data.status === true) {
                setYoutubeToken(res.data.next_page_token);
                let arr = [...youtubeResponse];

                setYoutubeResponse(arr.concat(res.data.data));
            }
            setLoader(false);
            setYoutubeLoad(false);
        })
    }


    useEffect(() => {
        searchGoogleLocation();
    }, []);

    useEffect(() => {

        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        axios.post(`${baseURL}fetch-google-keys`, {}, options).then(res => {
            if (res.data.status === true) {
                setGoogleKeys(res.data.data.api_key)
            }

        })
    }, [])

    // Google Places
    const getAddressObject = (address_components) => {
        const ShouldBeComponent = {
            street_number: ["street_number"],
            postal_code: ["postal_code"],
            street: ["street_address", "route"],
            province: [
                "administrative_area_level_1",
                "administrative_area_level_2",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            city: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4"
            ],
            country: ["country"]
        };

        let address = {
            street_number: "",
            postal_code: "",
            street: "",
            province: "",
            city: "",
            country: ""
        };

        address_components.forEach((component) => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === "country") {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });

        // Fix the shape to match our schema
        address.address = address.street_number + " " + address.street;

        setCountryCode(address.country);

        delete address.street_number;
        delete address.street;
        if (address.country === "US") {
            address.state = address.province;
            delete address.province;
        }
        return address;
    };

    useEffect(() => {
        const func = async () => {
            const geocodeObj = address && address.value && (await geocodeByPlaceId(address.value.place_id));
            const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components);

            setAddressObj(addressObject);

            address && address.value && await geocodeByAddress(address.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) =>
                    setLatLng({ ...latLng, lat: lat, long: lng })
                ).catch(error => console.log(error, 'errrrrrr'));
        };
        func();

    }, [address]);


    const sortYoutubeLike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.like - a.stats.like)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeView = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.views - a.stats.views)

        setYoutubeResponse(sortData);
    }

    const sortYoutubeComments = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.comments - a.stats.comments)
        console.log(sortData, "sortDatasortData")

        setYoutubeResponse(sortData);
    }

    const sortYoutubeDislike = () => {
        let sortData = [...youtubeResponse].sort((a, b) => b.stats.dislike - a.stats.dislike)

        setYoutubeResponse(sortData);
    }


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Trends </title>
            </Helmet>
            <div className="wrapper-box">

                <Navbar />
                <BannerLinebar />

                <section className="sec-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 d-flex align-items-center">
                                <div className="sec-1-heading newline">
                                    <ul className="list-inline m-0 d-flex">
                                        <li><NavLink to="#">Trends</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="trands-sec">
                    <div className="container">
                        <form className="row" method="post" onSubmit={(e) => searchTopic(e)}>
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="trands-column">*/}
                            {/*        <div className="row">*/}
                            <div className="col-lg-3 col-md-6 ">
                                <div className="social-platform">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Select Social
                                            Platform</label>
                                        <select className="form-control" id="exampleFormControlSelect1"
                                            name="social"
                                            onChange={(e) => onInputChange(e)}>
                                            <option selected value="reddit">Reddit</option>
                                            {/* <option value="twitter">Twitter</option> */}
                                            <option value="youtube">Youtube</option>
                                            <option value="google">Google</option>
                                        </select>
                                    </div>

                                </div>
                            </div>

                            {
                                trendState.social === 'reddit' ?
                                    <div className="col-md-3">
                                        <div className="trands-type">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Type</label>
                                                <select className="form-control" id="exampleFormControlSelect1"
                                                    name="type" onChange={(e) => onInputChange(e)}
                                                    required={trendState.social === 'reddit' || trendState.social === 'google' ? true : false}
                                                >
                                                    <option disabled>Select</option>
                                                    {
                                                        trendState.social === 'reddit' || trendState.social === 'google' ?
                                                            <>
                                                                <option value="relevance">Relevance</option>
                                                                <option value="hot">Hot</option>
                                                                <option value="top">Top</option>
                                                                <option value="new">New</option>
                                                            </>
                                                            : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }


                            {
                                trendState.social === 'twitter' ?
                                    <div className="col-lg-3 col-md-6 form-group">
                                        <label htmlFor="exampleFormControlInput1">Enter Location</label>
                                        <Select
                                            options={twitterLocation}
                                            value={twitterLoc}
                                            onChange={(value) => onLocationTwitterChange(value)}
                                        />

                                        {/* <GooglePlacesAutocomplete
                                            apiKey={googleKeys}
                                            selectProps={{
                                                isClearable: true,
                                                value: address,
                                                onChange: (val) => {
                                                    setAddress(val);
                                                }
                                            }}
                                        /> */}
                                    </div>
                                    : ''
                            }
                            {
                                trendState.social === 'google' ?
                                    <div className="col-lg-3 col-md-6 form-group">
                                        <label htmlFor="exampleFormControlInput1">Enter Location</label>
                                        <Select
                                            options={googleLocation}
                                            value={googleLoc}
                                            onChange={(value) => onLocationChange(value)}
                                        />
                                    </div>
                                    : ''
                            }


                            {
                                trendState.social !== "twitter" && trendState.social !== "google" ?
                                    <div className="col-lg-3 col-md-6">
                                        <div className="trands-keyword">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlInput1">Enter A Keyword</label>
                                                <input type="text" className="form-control"
                                                    name="keyword" pattern="[a-z A-Z 0-9]*" title="Input String Value"
                                                    onChange={(e) => onInputChange(e)}
                                                    id="exampleFormControlInput1" placeholder="Keyword"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }
                            {
                                trendState.social === "googles" ?
                                    <div className="col-lg-3 col-md-6">
                                        <div className="trands-keyword">
                                            <div className="form-group">
                                                <label>Timezone</label>
                                                <select className="form-control" name="timezone"
                                                    onChange={(e) => onInputTimezone(e)}
                                                >
                                                    <option value="-60">Last 1 Hour</option>
                                                    <option value="-180">Last 3 Hour</option>
                                                    <option value="-360">Last 6 Hour</option>
                                                    <option value="2-d">Last 1 Day</option>
                                                    <option value="3-d">Last 3 Day</option>
                                                    <option value="7-d">Last Week</option>
                                                    <option value="1-m">Last Month</option>
                                                    <option value="3-m">Last 3 Month</option>
                                                    <option value="6-m">Last 1 Month</option>
                                                    <option value="1-y">Last 1 Year</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }

                            {
                                trendState.social === "youtube" ?
                                    <div className="col-lg-3 col-md-6  d-flex align-items-center">
                                        <div className="form-check form-switch mt-3">
                                            <input className="form-check-input" name="send" type="checkbox" value="1" checked={commonLic} onChange={(e) => onCheckCommon(e)} />
                                        </div>
                                        <label style={{ fontSize: ".875em", marginTop: "20px" }}>Creative Common License</label>
                                    </div>
                                    : ""
                            }

                            <div className="col-lg-3 col-md-6  d-flex align-items-center">
                                <div className="trands-search">
                                    <button type="submit" className="btn" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Search
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </section>


                <section className="tranding-post-sec" style={{ padding: "0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Trending Result</h5>

                                {
                                    trendState.social === "youtube" ?
                                        youtubeResponse.length > 0 ?
                                            <div className="trands-table-box">

                                                <table className="table table-responsive">
                                                    <thead className="theadbgcolor">
                                                        <tr style={{ fontSize: "13px" }}>
                                                            <th colSpan={1} scope="col" style={{ fontWeight: "400" }} >Youtube Videos</th>
                                                            <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeView()} title="Most Views">View <i className="fa fa-sort"></i></th>
                                                            <th scope="col" style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeLike()} title="Most Likes">Like <i className="fa fa-sort"></i></th>
                                                            <th colSpan={2} style={{ width: "150px", fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeComments()} title="Most Comments">Comments <i className="fa fa-sort"></i></th>
                                                            <th className='d-flex align-items-center gap-1 me-3' style={{ width: "50px", fontWeight: "400", cursor: "pointer" }}
                                                                onClick={(e) => sortYoutubeDislike()} title="Most Dislikes">Dislike <i className="fa fa-sort"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            youtubeResponse.length > 0 ?
                                                                youtubeResponse.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <th style={{ width: "70%" }} scope="row">
                                                                                {
                                                                                    <section className="">
                                                                                        <div className="container pl-0">
                                                                                            <div className="row">

                                                                                                <div
                                                                                                    className="col-lg-12"
                                                                                                    style={{ marginBottom: "1rem" }}>
                                                                                                    <div
                                                                                                        className="youtube-box-content"
                                                                                                        style={{
                                                                                                            boxShadow: "0px 0px 5px #a2a2a2d1",
                                                                                                            backgroundColor: "#eeeeee",
                                                                                                            overflow: "hidden"
                                                                                                        }}>
                                                                                                        <div className="row">
                                                                                                            <div
                                                                                                                className="col-md-4 pr-0 "
                                                                                                                style={{ backgroundColor: "#eee" }}>
                                                                                                                <img
                                                                                                                    style={{ width: "100%" }}
                                                                                                                    src={item.thumbnail}
                                                                                                                    className="img-fluid" />

                                                                                                                <div
                                                                                                                    className="row mt-2 youtube-content">
                                                                                                                    <div
                                                                                                                        className="col-md-12">
                                                                                                                        <div
                                                                                                                            className="view-visit-download">
                                                                                                                            <ul className="list-inline m-0 d-flex justify-content-between">
                                                                                                                                <li>
                                                                                                                                    <a
                                                                                                                                        target="_blank"
                                                                                                                                        href={`https://www.youtube.com/watch?v=${item.id}`}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{ fontSize: "10px" }}><i
                                                                                                                                                className="fas fa-play-circle"></i></span>Watch
                                                                                                                                    </a>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <a onClick={(e) => downloadYoutube(item.id)}><span>
                                                                                                                                        <i className="fas fa-download"></i></span>Download
                                                                                                                                    </a>
                                                                                                                                </li>

                                                                                                                            </ul>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>


                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-md-8 pl-0">
                                                                                                                <div
                                                                                                                    className="youtube-content">
                                                                                                                    <h5 className="m-0">{item.title}</h5>
                                                                                                                    {/* <h6 className="m-0" style={{position:"absolute" , right:"17px" , top:"8px" , color:"#16d489" , cursor:"pointer"}}  ><i class="far fa-copy" data-for="mainTooltip" data-iscapture="true"  data-tip="copy this title"></i>
                                                                        <ReactTooltip
                                                                            id="mainTooltip"
                                                                            place="top"
                                                                            multiline={true}
                                                                        />
                                                                        </h6> */}
                                                                                                                    <span
                                                                                                                        className="d-block"><a
                                                                                                                            target="_blank" href={`https://www.youtube.com/channel/${item.channel_id}`}>{item.channel_title}</a>- 2 months ago</span>
                                                                                                                    <p id="toggleDesc"
                                                                                                                        className="youtube-desc">
                                                                                                                        {item.description}
                                                                                                                    </p>
                                                                                                                    {/*<p className="m-0"><a onClick={(e)=>toggleDesc()} style={{textDecoration:"none"  ,cursor:"pointer" }} >SHOW MORE</a></p>*/}

                                                                                                                    <div
                                                                                                                        className="youtube-tages">
                                                                                                                        <ul className="d-flex flex-wrap list-inline">
                                                                                                                            {
                                                                                                                                item.tags !== null ?
                                                                                                                                    item.tags.length > 0 ?
                                                                                                                                        item.tags.slice(0, 10).map((item, index) => {
                                                                                                                                            return (
                                                                                                                                                <li key={index}
                                                                                                                                                    style={{
                                                                                                                                                        backgroundColor: "#fff",
                                                                                                                                                        margin: "3px",
                                                                                                                                                        padding: "3px",
                                                                                                                                                        color: "#000",
                                                                                                                                                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                                                                                                                                                        fontSize: "10px",
                                                                                                                                                        borderRadius: "5px"
                                                                                                                                                    }}>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: "10px",
                                                                                                                                                            marginRight: "3px"
                                                                                                                                                        }}><i
                                                                                                                                                            className="fas fa-tag"></i></span>
                                                                                                                                                    {item}
                                                                                                                                                </li>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                        : ''
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>





                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </section>

                                                                                }
                                                                            </th>
                                                                            <th style={{ width: "50px", fontWeight: "400" }} >{item.stats.views}</th>
                                                                            <th style={{ width: "50px", fontWeight: "400" }} >{item.stats.like}</th>
                                                                            <th style={{ width: "50px", fontWeight: "400" }} >{item.stats.comments ? item.stats.comments : "0"}</th>
                                                                            <th className='text-center' colSpan={2} style={{ width: "50px", fontWeight: "400" }} >{item.stats?.dislike ? item.stats?.dislike : "0"}</th>
                                                                        </tr>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </tbody>
                                                </table>
                                                {
                                                    youtubeLoader === true ?
                                                        <div className="loaderbox">
                                                            <div className="loader"></div>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    trendState.social === 'youtube' ?
                                                        youtubeResponse.length > 0 ?
                                                            youtubeToken !== '' ?
                                                                <button style={{ margin: "auto" }}
                                                                    disabled={youtubeLoad}
                                                                    onClick={(e) => searchYoutubeSocial()}
                                                                    className="btn-submit">
                                                                    {youtubeLoad ?
                                                                        <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load
                                                                    more
                                                                </button>
                                                                : ''
                                                            : ''
                                                        : ''
                                                }

                                            </div>
                                            : ''
                                        : ''
                                }

                                <div className="trands-table-box mt-4 text-center">
                                    <div className="row">
                                        <div className="col-md-12">

                                            {
                                                trendState.social === 'twitter' ?
                                                    <table className="table table-hover  table-responsive-sm">
                                                        <thead className="theadbgcolor">
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                twitterResponse.length > 0 ?
                                                                    <tr>
                                                                        <td colSpan="4"><h4
                                                                            className="text-left">Twitter</h4>
                                                                            <ul className="list-inline twitter-item-box  m-0 d-flex flex-wrap  ">
                                                                                {
                                                                                    twitterResponse.map((item, index) => {
                                                                                        return (
                                                                                            <li key={index}>
                                                                                                <span><i
                                                                                                    className="fas fa-tag"></i></span>
                                                                                                {item.name}
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    : ''
                                                            }
                                                        </tbody>
                                                    </table>
                                                    : ''
                                            }


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {
                    trendState.social === 'google' ?
                        googleResponse.length > 0 ?
                            <section className="py-5">
                                <div className="container">
                                    <div className="row">
                                        {
                                            googleResponse.map((item, index) => {
                                                return (
                                                    <GoogleTrend item={item} index={index} key={index} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    trendState.social === 'reddit' ?
                                        redditResponse.length > 0 ?
                                            redditToken !== '' ?
                                                <button style={{ margin: "auto" }} disabled={redditLoad}
                                                    onClick={(e) => loadMoreReddit()}
                                                    className="btn-submit">
                                                    {redditLoad ?
                                                        <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load
                                                    more
                                                </button>
                                                : ''
                                            : ''
                                        : ''
                                }
                            </section>
                            : ''
                        : ''
                }

                {
                    trendState.social === 'reddit' ?
                        redditResponse.length > 0 ?
                            <section className="py-5">
                                <div className="container">
                                    <div className="row">
                                        {
                                            redditResponse.map((item, index) => {
                                                return (
                                                    <div key={index} className="col-lg-6"
                                                        style={{ marginBottom: "1rem" }}>
                                                        <div className="youtube-box-content"
                                                            style={{
                                                                boxShadow: "0px 0px 5px #a2a2a2d1",

                                                                overflow: "hidden"
                                                            }}>
                                                            <div className="row">
                                                                <div
                                                                    className="col-md-4   pr-0 d-flex align-items-start"
                                                                    style={{ backgroundColor: "#eee" }}>
                                                                    <img style={{ width: "100%" }}
                                                                        src={redditArr.includes(item.data.thumbnail) ? redditImg : item.data.thumbnail}
                                                                        className="img-fluid" />
                                                                </div>
                                                                <div className="col-md-8 pl-0 " style={{
                                                                    backgroundColor: "#eee",
                                                                    position: "relative"
                                                                }}>
                                                                    <div className="youtube-content">
                                                                        <h5 className="m-0">
                                                                            {item.data.title}
                                                                        </h5>
                                                                        {/* <h6 className="m-0" style={{position:"absolute" , right:"20px" , top:"36px" , color:"#16d489" , cursor:"pointer"}}  ><i class="far fa-copy" data-for="mainTooltip" data-iscapture="true"  data-tip="copy this content"></i>
                                                                        <ReactTooltip
                                                                            id="mainTooltip"
                                                                            place="top"
                                                                            multiline={true}
                                                                        />
                                                                        </h6> */}
                                                                        {/*<span className="d-block"><a*/}
                                                                        {/*    href="#">{item.channel_title}</a>- 2 months ago</span>*/}
                                                                        <p title={item.data.all_awardings.length > 0 ? item.data.all_awardings[0].description : ''}>
                                                                            {item.data.all_awardings.length > 0 ? item.data.all_awardings[0].description : '-'}
                                                                        </p>


                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="view-visit-download">
                                                                                    <ul className="list-inline m-0 d-flex justify-content-between mt-3">
                                                                                        <li>
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            >
                                                                                                <span><i
                                                                                                    className="fas fa-play-circle"></i></span>Visit
                                                                                            </a>
                                                                                        </li>
                                                                                        <li className="votesonly text-center">
                                                                                            <div>
                                                                                                <h6 className="view-like-share-count m-0 "
                                                                                                    style={{ fontSize: "10px" }}>{item.data.ups}</h6>
                                                                                                <h6 className="view-like-share"
                                                                                                    style={{ fontSize: "10px" }}>
                                                                                                    <span><i
                                                                                                        class="fas fa-long-arrow-alt-up"></i></span>Votes
                                                                                                </h6>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className="votesonly text-center">
                                                                                            <div>
                                                                                                <h6 className="view-like-share-count m-0 "
                                                                                                    style={{ fontSize: "10px" }}>{item.data.subreddit_subscribers}</h6>
                                                                                                <h6 className="view-like-share"
                                                                                                    style={{ fontSize: "10px" }}>
                                                                                                    <span><i
                                                                                                        className="fas fa-users"></i></span>Subscribers
                                                                                                </h6>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li className="votesonly text-center">
                                                                                            <div>
                                                                                                <h6 className="view-like-share-count m-0 "
                                                                                                    style={{ fontSize: "10px" }}>{item.data.num_comments}</h6>
                                                                                                <h6 className="view-like-share"
                                                                                                    style={{ fontSize: "10px" }}>
                                                                                                    <span><i
                                                                                                        className="fas fa-comments"></i></span>Comments
                                                                                                </h6>
                                                                                            </div>
                                                                                        </li>

                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="row mt-2">
                                                                            <div className="col-md-3 text-center social-icon-share d-flex">
                                                                                {
                                                                                    <>
                                                                                        <FacebookShareButton
                                                                                            url="www.google.com"
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <FacebookIcon />
                                                                                        </FacebookShareButton>

                                                                                        <EmailShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <EmailIcon />
                                                                                        </EmailShareButton>

                                                                                        <LinkedinShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <LinkedinIcon />
                                                                                        </LinkedinShareButton>

                                                                                        <PinterestShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <PinterestIcon />
                                                                                        </PinterestShareButton>

                                                                                        <RedditShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <RedditIcon />
                                                                                        </RedditShareButton>

                                                                                        <TelegramShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <TelegramIcon />
                                                                                        </TelegramShareButton>

                                                                                        <PocketShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <PocketIcon />
                                                                                        </PocketShareButton>

                                                                                        <TwitterShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <TwitterIcon />
                                                                                        </TwitterShareButton>

                                                                                        <WhatsappShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <WhatsappIcon />
                                                                                        </WhatsappShareButton>

                                                                                        <TumblrShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <TumblrIcon />
                                                                                        </TumblrShareButton>

                                                                                        <InstapaperShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <InstapaperIcon />
                                                                                        </InstapaperShareButton>

                                                                                        <ViberShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <ViberIcon />
                                                                                        </ViberShareButton>

                                                                                        <VKShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <VKIcon />
                                                                                        </VKShareButton>

                                                                                        <WorkplaceShareButton
                                                                                            url={`https://www.reddit.com/${item.data.permalink}`}
                                                                                            quote={`https://www.reddit.com/${item.data.permalink}`}>
                                                                                            <WorkplaceIcon />
                                                                                        </WorkplaceShareButton>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                    trendState.social === 'reddit' ?
                                        redditResponse.length > 0 ?
                                            redditToken !== '' ?
                                                <button style={{ margin: "auto" }} disabled={redditLoad}
                                                    onClick={(e) => loadMoreReddit()}
                                                    className="btn-submit">
                                                    {redditLoad ?
                                                        <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load
                                                    more
                                                </button>
                                                : ''
                                            : ''
                                        : ''
                                }
                            </section>
                            : ''
                        : ''
                }

            </div>
        </>
    )
}

export default Trends;

