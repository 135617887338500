import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import Accard from "./Accard";
import edit from "../../images/edit.png";
import del from "../../images/delete.png";
import {fetchAppClient} from "../../store/actions/authAction";

function Aclients() {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [appClients,setAppClient] = useState(auth.appClients);

    const [clientCount, setClientCount] = useState(auth.appClients.length);


    const [q, setQ] = useState('');
    const getQ = (e) => {
        setQ(e.target.value)
    };

    const search = (users) => {
        return users.filter(
            user => user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 || user.email.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    };

    useEffect(()=>{
        setAppClient(auth.appClients);
        setClientCount(auth.appClients.length);

        return () => {
            setAppClient('');
        };

    },[auth.appClients]);

    useEffect(()=>{
        dispatch(fetchAppClient(auth.token));
    },[])

    return (
        <>
           <section className="sec-1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-4 d-flex align-items-center">
                    <div className="sec-1-heading newline">
                      <ul className="list-inline m-0 d-flex">
                        <li><NavLink to="#">My Clients</NavLink></li>
                        <li className="ml-lg-5 ml-3">
                            <Link to="/clients">{clientCount} Clients</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6  col-md-8  d-flex justify-content-lg-end">
                    <div className="search-add d-flex align-items-center ">
                      <div className="search-container mr-lg-3">
                        <form>
                          <input type="text" placeholder="Search.." onChange={(e)=>getQ(e)} name="search" />
                          <button type="submit"><i className="fa fa-search" /></button>
                        </form>
                      </div>
                      <div className="add d-flex ml-md-2 ml-lg-0 align-items-center">
                          {
                              auth.user.client == "0" ?
                                  <Link to="/add-client" className="button"><span><i className="fas fa-plus-circle mr-2" /></span>  Add Client</Link>
                              : ''
                          }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="deshborad-sec dashboard-custom">
                <div className="container">
                    <div className="row mt-4">
                        {
                            auth.user.client === "1" ?
                                <div className="col-lg-3 mb-5 col-md-6" key={auth.user.id}>
                                    <Accard
                                        editimg={edit}
                                        edithead="Edit"
                                        del={del}
                                        delhead="Delete"
                                        projname={auth.user.name}
                                        date={auth.user.created}
                                        id={auth.user.id}
                                        profile={auth.user.thumbnail !== '' ? auth.user.thumbnail : auth.user.image}
                                    />
                                </div>
                                :
                            appClients.length > 0 ?
                                search(appClients).map((client)=>{
                                    return(
                                        <div className="col-lg-3 mb-5 col-md-6" key={client.id}>

                                            <Accard
                                                editimg={edit}
                                                edithead="Edit"
                                                del={del}
                                                delhead="Delete"
                                                projname={client.name}
                                                date={client.created}
                                                id={client.id}
                                                profile={client.thumbnail !== '' ? client.thumbnail : client.image}
                                            />

                                        </div>
                                    )
                                })
                                : <div className="col-md-12">
                                    <h5 className="text-center">You have not created any client yet. Click 'Add Client' to create one</h5>
                                </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aclients
