import React from 'react'
import Footer from './SubComponent/Footer'
import Navbar from './SubComponent/Navbar'
import NotificationCom from './SubComponent/NotificationCom'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { useSelector } from 'react-redux';
import { appName } from '../global/global';

function Notification() {
  const brandName = useSelector(state => state.rebrand.data);

  return (
    <>
      <Helmet>
        <title>{brandName !== false ? brandName.name : appName} | Notification </title>
      </Helmet>
      <Navbar />
      <BannerLinebar />
      <NotificationCom />
      <Footer />
    </>
  )
}

export default Notification
