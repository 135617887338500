import React, { useState } from "react";
import LoginLogo from "../images/loginlogo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../store/actions/authAction";
import Alert from "./alert/alert";
import { Helmet } from "react-helmet";
import { appName } from "../global/global";

const ForgetPassword = () => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: null
    });
    const rebrandData = useSelector(state => state.rebrand.data);

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }
    const brandName = useSelector(state => state.rebrand.data);

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(forgetPassword(user, setLoader));
    }

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Forgot Password </title>
            </Helmet>
            <section className="login-sec">
                <div className="container">
                    <Alert />
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="login">
                                <div className="row">
                                    <div className="col-lg-5   col-md-12 d-flex align-items-center">
                                        <div className="login-content">
                                            <div className="logo-img-line">
                                                <img
                                                    className="img-fluid"
                                                    src={rebrandData?.logo || LoginLogo}
                                                    alt="Logo"
                                                    height="auto"
                                                />
                                                {/* <img src={LoginLogo} alt className="img-fluid" /> */}
                                            </div>
                                            <div className="logo-head text-white">
                                                <h5>Professional Agency Business  <span className="d-lg-block"> FOR You With just Minutes of work</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 offset-lg-1 col-md-12">
                                        <div className="login-account">

                                            <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                                <h5 className="text-center">Enter Your Email Address</h5>
                                                <div className="form-group mt-5">
                                                    <label htmlFor="exampleInputEmail1">Email Address</label>
                                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required
                                                        placeholder="youremail@gmail.com" name="email" onChange={(e) => onInputChange(e)} />
                                                </div>


                                                <button type="submit" className="button mt-4"
                                                    disabled={loader}>{loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit</button>

                                                <div className="forget-sec m-4 text-center">
                                                    <h6 className="m-0">Login ? <span><Link to="login">Click here</Link></span> </h6>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}

export default ForgetPassword;