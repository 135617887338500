import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { changePassword } from "../../store/actions/authAction";
import Alert from "../alert/alert";

function Myacpcom() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    password: '',
    new_password: '',
    confirm_password: ''
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    new_password: false,
    confirm_password: false,
  });

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!validatePassword(user.new_password)) {
      alert("Password must include at least 1 uppercase, 1 lowercase, 1 number, 1 special character and should be at least 8 characters long");
      return;
    }
    if (user.new_password !== user.confirm_password) {
      alert("New password and confirm password do not match.");
      return;
    }

    setLoader(true);
    dispatch(changePassword(user, setLoader));
  }

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return password.length >= minLength && hasUpperCase && hasNumber;
  }

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  }

  return (
    <>
      <section className="password-sec">
        <div className="container">
          <Alert />
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="personal-heading">
                <h5>Change Password</h5>
              </div>
            </div>
          </div>
          <div className="row mt-2 d-flex justify-content-center">
            <div className="col-md-12 col-lg-10">
              <form className="personal-box line" method="post" onSubmit={onFormSubmit}>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-6">
                    <div className="form-group">
                      <small className="form-text text-muted">Current Password</small>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input type={showPassword.password ? "text" : "password"} required placeholder="Current Password" className="form-control"
                          name="password" onChange={onInputChange} />
                        <i onClick={() => togglePasswordVisibility('password')} className={`fa ${showPassword.password ? "fa-eye" : "fa-eye-slash"}`} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 mt-lg-4">
                    <div className="form-group">
                      <small className="form-text text-muted">New Password</small>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input type={showPassword.new_password ? "text" : "password"} required placeholder="New Password" className="form-control"
                          name="new_password" onChange={onInputChange} />
                        <i onClick={() => togglePasswordVisibility('new_password')} className={`fa ${showPassword.new_password ? "fa-eye" : "fa-eye-slash"}`} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-lg-4">
                    <div className="form-group">
                      <small className="form-text text-muted">Confirm New Password</small>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input type={showPassword.confirm_password ? "text" : "password"} required placeholder="Confirm Password" className="form-control"
                          name="confirm_password" onChange={onInputChange} />
                        <i onClick={() => togglePasswordVisibility('confirm_password')} className={`fa ${showPassword.confirm_password ? "fa-eye" : "fa-eye-slash"}`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="update">
                      <button type="submit" className="button" disabled={loader}>
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Myacpcom;
