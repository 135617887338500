import './App.css';
import './WhiteLabel.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Aaddpage from './Components/Aaddpage';
import AClient from './Components/AClient';
import CreateStream from './Components/CreateStream';
import CreateVideo from './Components/CreateVideo';
import Deshboard from './Components/Deshboard';
import GDPR from './Components/GDPR';
import Login from './Components/Login';
import Myaccount from './Components/Myaccount';
import Notification from './Components/Notification';
import Popup from './Components/Popup';
import ScheduleContent from './Components/ScheduleContent';
import ScheduleDetails from './Components/ScheduleDetails';
import Stream from './Components/Stream';
import TeamMember from './Components/TeamMember';
import Training from './Components/Training';
import Training2 from './Components/Training2';
import Upgrades from './Components/Upgrades';
import ImageCreator from './Components/ImageCreator';
import Footer from "./Components/SubComponent/Footer";
import ForgetPassword from "./Components/ForgetPassword";
import { PrivateRoute } from "./Components/PrivateRoute";
import ResetPassword from "./Components/ResetPassword";
import EditClient from "./Components/EditClient";
import SocialConnectCode from "./Components/SubComponent/SocialConnectCode";
import VimeoCallback from "./Components/SubComponent/VimeoCallback";
import RedditCallback from "./Components/SubComponent/RedditCallback";
import EditStream from "./Components/EditStream";
import Scheduler from "./Components/Scheduler";
import Reports from "./Components/Reports";
import ReportGenerate from "./Components/SubComponent/ReportGenerate";
import Trends from "./Components/Trends";
import Contents from "./Components/Contents";
import ResellerAccount from "./Components/ResellerAccount";
import FindClients from "./Components/SubComponent/FindClients";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, onFetchWhiteLabelData } from "./store/actions/authAction";
import ArticleDetails from "./Components/SubComponent/ArticleDetails";
import Agency from "./Components/Agency";
import MasterLogin from "./Components/MasterLogin";
import WhiteLabelTabs from './Components/WhiteLabel/WhiteLabelTabs';
import { removeAlert } from './store/actions/alert';


function App() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const rebrand = useSelector(state => state.rebrand.data)


    useEffect(() => {
        dispatch(loadUser())
    }, [])
    const fetchWhiteLabel = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let obj = { domain: window.location.host }
        dispatch(onFetchWhiteLabelData(obj, loader, setLoader))
    }
    useEffect(() => {
        if (rebrand && rebrand.color?.first && rebrand.color?.second) {
            document.documentElement.style.setProperty('--primary', rebrand.color.first);
            document.documentElement.style.setProperty('--secondary', rebrand.color.second);
        }
    }, [rebrand])

    useEffect(() => {

        fetchWhiteLabel();
    }, [])
    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    dispatch(removeAlert())
                }, 4000);
            }
        }
    }, [alert, dispatch])

    useEffect(() => {
        dispatch(loadUser())
    }, [])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />

                    <PrivateRoute exact path="/add-client" component={Aaddpage} />
                    <PrivateRoute exact path="/clients" component={AClient} />

                    <PrivateRoute exact path="/edit-client/:id" component={EditClient} />
                    <PrivateRoute exact path="/create-stream" component={CreateStream} />
                    <PrivateRoute exact path="/edit-stream/:id" component={EditStream} />
                    <PrivateRoute exact path="/create-video" component={CreateVideo} />

                    <PrivateRoute exact path="/dashboard" component={Deshboard} />
                    <PrivateRoute exact path="/privacy" component={GDPR} />

                    <PrivateRoute exact path="/my-account" component={Myaccount} />
                    <PrivateRoute exact path="/notification" component={Notification} />
                    <PrivateRoute exact path="/popup" component={Popup} />
                    <PrivateRoute exact path="/schedule-content" component={ScheduleContent} />
                    <PrivateRoute exact path="/schedule-detail/:id" component={ScheduleDetails} />
                    <PrivateRoute exact path="/scheduler" component={Scheduler} />
                    <PrivateRoute exact path="/streams" component={Stream} />
                    <PrivateRoute exact path="/account-management" component={TeamMember} />
                    <PrivateRoute exact path="/help-and-support" component={Training} />
                    <PrivateRoute exact path="/training" component={Training2} />
                    <PrivateRoute exact path="/article-details" component={ArticleDetails} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/image-creator" component={ImageCreator} />
                    <PrivateRoute exact path="/report" component={Reports} />
                    <PrivateRoute exact path="/generate-report/:id/:month/:year" component={ReportGenerate} />
                    <PrivateRoute exact path="/trends" component={Trends} />
                    <PrivateRoute exact path="/contents" component={Contents} />
                    <PrivateRoute exact path="/reseller-account" component={ResellerAccount} />
                    <PrivateRoute exact path="/find-clients" component={FindClients} />
                    <PrivateRoute exact path="/agency" component={Agency} />
                    <PrivateRoute exact path="/white-label" component={WhiteLabelTabs} />

                    {/* Callback URLs */}
                    <PrivateRoute exact path="/vimeo-callback" component={VimeoCallback} />
                    <PrivateRoute exact path="/reddit-callback" component={RedditCallback} />

                    <Route exact path="/forgot-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />
                    <Route exact path="/social-connect-code" component={SocialConnectCode} />
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
