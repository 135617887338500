import React, { useEffect, useState } from 'react';
import Navbar from "./SubComponent/Navbar";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import user1 from "../images/user1.png";
import swal from "sweetalert";
import axios from "axios";
import { appName, baseURL } from "../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../store/actions/alert";
import BannerLinebar from "./SubComponent/BannerLinebar";

const Agency = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [agency, setAgency] = useState([]);
    const [agencyStatus, setAgencyStatus] = useState(false);
    const [fAgency, setFAgency] = useState(true);
    const brandName = useSelector(state => state.rebrand.data);

    const [agencyData, setAgencyData] = useState({
        id: '',
        name: '',
        email: '',
        site: '',
        logo: ''
    })


    const onInputChange = (e) => {
        setAgencyData({ ...agencyData, [e.target.name]: e.target.value });
    }

    const onFileInput = (e) => {

        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' ||
            e.target.files[0].type === 'image/jpeg') {

            if (e.target.files[0].size < 5000000) {
                setAgencyData({ ...agencyData, logo: e.target.files[0] });
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const addAgency = async (data) => {
        await axios({
            method: "POST",
            url: `${baseURL}agency`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchAgency();
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const fetchAgency = async () => {
        await axios({
            method: "POST",
            url: `${baseURL}fetch-agency`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            console.log(res);
            if (res.data.status === true) {
                setAgency(res.data.data);
                setAgencyData({
                    ...agencyData,
                    id: res.data.data.id,
                    name: res.data.data.name,
                    email: res.data.data.email,
                    site: res.data.data.site
                });
                setAgencyStatus(true);
            } else {
                setAgencyStatus(false);
            }
            setFAgency(false);
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setFAgency(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        let formData = new FormData();
        formData.append('name', agencyData.name);
        formData.append('email', agencyData.email);
        formData.append('site', agencyData.site);
        formData.append('logo', agencyData.logo);

        addAgency(formData);
    }

    const onFormUpdate = (e) => {
        e.preventDefault();
        setLoader(true);

        let formData = new FormData();
        formData.append('id', agency.id);
        formData.append('name', agencyData.name);
        formData.append('email', agencyData.email);
        formData.append('site', agencyData.site);
        formData.append('logo', agencyData.logo);

        addAgency(formData);
    }


    useEffect(() => {
        fetchAgency();
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Agency </title>
            </Helmet>
            <Navbar />
            <BannerLinebar />

            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><NavLink to="#">My Agency</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-8  d-flex justify-content-lg-end">
                            <div className="search-add d-flex align-items-center ">

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="deshborad-sec d-flex align-items-center form-card" style={{ padding: "20px 0px 40px", height: "100vh" }}>
                <div className="container">
                    <div className="row mt-4 d-flex justify-content-center">
                        <div className="col-lg-5">
                            {
                                agencyStatus ?
                                    <form method="post" onSubmit={(e) => onFormUpdate(e)}>
                                        <div className="user-img">
                                            <input type="file" id="file1" name="image" accept="image/*" onChange={(e) => onFileInput(e)} />
                                            <img src={agency.logo} id="upfile1" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <div className="form-group mt-3">
                                            <small id="emailHelp" className="form-text text-muted">Name</small>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Agency Name"
                                                defaultValue={agency.name}
                                                required name="name" onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <small id="emailHelp" className="form-text text-muted">Email Address</small>
                                            <input type="email" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Email Address"
                                                defaultValue={agency.email}
                                                required name="email" onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <small id="emailHelp" className="form-text text-muted">Website</small>
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Your Website" name="site" defaultValue={agency.site}
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="creat-btn mt-4 d-flex justify-content-end">
                                            <button type="submit" className="button btn-submit" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                                            </button>
                                        </div>
                                    </form>
                                    :
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="user-img">
                                            <input type="file" id="file1" required name="image" accept="image/*" onChange={(e) => onFileInput(e)} />
                                            <img src={user1} id="upfile1" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <div className="form-group mt-3">
                                            <small id="emailHelp" className="form-text text-muted">Name</small>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Agency Name"
                                                required name="name" onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <small id="emailHelp" className="form-text text-muted">Email Address</small>
                                            <input type="email" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Email Address"
                                                required name="email" onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <small id="emailHelp" className="form-text text-muted">Website</small>
                                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Your Website" name="site"
                                                onChange={(e) => onInputChange(e)}
                                            />
                                        </div>

                                        <div className="creat-btn mt-4 d-flex justify-content-end">
                                            <button type="submit" className="button btn-submit" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Create
                                            </button>
                                        </div>
                                    </form>
                            }
                            <h5 className="" style={{ opacity: ".5", marginTop: "20px" }}>
                                <span >Email:-{agencyStatus ? agencyData.email : auth.user.email}</span><br />
                                <span>Website:-{agencyStatus ? agencyData.site : auth.user.name}</span>
                            </h5>

                            {
                                fAgency ?
                                    <div className="loaderbox">
                                        <div className="loader"></div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Agency;