import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/logo.png';
import user from '..//../images/user.png';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/actions/authAction";
import Alert from "../alert/alert";
import { MdOutlineInbox } from 'react-icons/md';


const Navbar = () => {

    let currentUrl = window.location.pathname;

    const myContents = ['/create-video', '/image-creator', '/contents'];
    const myClients = ['/clients', '/find-clients'];

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [memberships, setMemberships] = useState([]);

    const [rebrand, setRebrand] = useState(rebrandData);

    const logout = (e) => {
        e.preventDefault();
        dispatch(logoutUser());
    }

    useEffect(() => {
        if (auth.user.membership !== null) {
            setMemberships(auth.user.membership.split("__"));
        }
    }, [])

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])
    return (
        <>
            <nav className="navbar navbar-expand-xl   navbar-light">
                <div className="container">
                    <Alert />
                    <div className='text-start' style={{ maxWidth: "150px", minWidth: "150px" }}>
                        <NavLink className="navbar-brand" to="">
                            <img src={rebrand.data !== false ? rebrand.data.logo : logo} alt className="img-fluid" style={{ width: "100%", objectFit: "contain" }} />
                        </NavLink>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span><i className="fas fa-bars" /></span>
                    </button>
                    <div className="collapse navbar-collapse small-navbar" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/dashboard"
                                    activeClassName="active-menu">Dashboard</NavLink>
                            </li>
                            {
                                auth.user.client == "0" ?
                                    <li className="nav-item dropdown">
                                        <a className={`nav-link dropdown-toggle ${myContents.includes(currentUrl) ? 'active-menu' : ''}`} tabIndex="0" href="#" id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            My Content
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <NavLink className="dropdown-item" to="/create-video">Create Video</NavLink>
                                            <NavLink className="dropdown-item" to="/image-creator">Create
                                                Image/Meme</NavLink>
                                            <NavLink className="dropdown-item" to="/contents">My Contents</NavLink>
                                        </div>
                                    </li>
                                    : ''
                            }
                            <li className="nav-item dropdown">
                                <a className={`nav-link dropdown-toggle ${myClients.includes(currentUrl) ? 'active-menu' : ''} `} href="#" id="navbarDropdown1" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {auth.user.client == "0" ? 'Clients' : 'Client'}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                    <NavLink className="dropdown-item" to="/clients">Manage {auth.user.client == "0" ? 'Clients' : 'Client'} </NavLink>
                                    {
                                        auth.user.client == "0" ?
                                            <NavLink className="dropdown-item" to="/find-clients">Find Clients</NavLink>
                                            : ''
                                    }

                                </div>
                            </li>
                            {
                                auth.user.client == "0" ?
                                    <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/streams" activeClassName="active-menu">Content
                                                Stream</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/scheduler"
                                                activeClassName="active-menu">Scheduler</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/trends"
                                                activeClassName="active-menu">Trends</NavLink>
                                        </li>
                                        {
                                            memberships.includes('enterprise') || memberships.includes('enterpriselite') ?
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" to="/report"
                                                        activeClassName="active-menu">Report</NavLink>
                                                </li>
                                                : ''
                                        }
                                    </>
                                    : ''
                            }


                            {/* <div className="dropdown  belldropdown mr-3 d-flex align-items-center ">
                    <button  className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       <img src={BELLICON} alt="" className="img-fluid" /> 10 <span><i class="fas fa-angle-down"></i></span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Yay!! Your scheduled post at 7/17/21, 10:30 AM has been published</a>
                      <a className="dropdown-item" href="#">Yay!! Your scheduled post at 7/17/21, 10:30 AM has been published</a>
                     
                    </div>
                  </div> */}


                            <div className="dropdown mt-4 mt-lg-0">
                                <button className="btn  btn-secondary navmenuid  dropdown-toggle" type="button"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span tabIndex="0"  ><img src={auth.user.image} alt className="img-fluid" /></span>
                                    <div className="text" title={auth.user?.name}>
                                        <h6 className="m-0" >Welcome</h6>{auth.user?.name}
                                    </div>

                                </button>
                                <div className="dropdown-menu navdropdown " aria-labelledby="dropdownMenuButton">

                                    {
                                        auth.user.is_client_account == 0 && auth.user.is_virtual_account == 0 && auth.user.client == 0 ?
                                            <>
                                                <Link className="dropdown-item" to="/my-account"><span className="mr-2">
                                                    <i className="fas fa-user" /></span> My Account
                                                </Link>
                                                <Link className="dropdown-item" to="/privacy"><span className="mr-2">
                                                    <i className="fas fa-key" /></span> Privacy Setting
                                                </Link>
                                            </> : ''
                                    }
                                    {auth.user.isWL === '0' && auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                        <>
                                            {/* {
                                        membership.includes("bundle") ? */}
                                            <Link className="dropdown-item" to="/white-label">
                                                <span className="mr-2"><MdOutlineInbox fontSize={20} /></span>White Label
                                            </Link>

                                        </>

                                        : ""
                                    }
                                    {
                                        auth.user.is_client_account == 0 && auth.user.is_virtual_account == 0 && auth.user.client == 0 ?

                                            auth.user.is_reseller_account == 0 ?
                                                memberships.includes('dfy') || memberships.includes('enterprise') || memberships.includes('enterpriselite') ?
                                                    <Link className="dropdown-item" to="/account-management"><span
                                                        className="mr-2"><i
                                                            className="fas fa-users" /></span> Account Management</Link>
                                                    : ''
                                                : ''

                                            : ''
                                    }
                                    {
                                        auth.user.is_client_account == 0 && auth.user.client == 0 && auth.user.is_reseller_account == 0 ?
                                            <>
                                                <Link className="dropdown-item" to="/upgrades"><span className="mr-2"><i
                                                    className="fas fa-plus-square" /></span> Upgrades</Link>
                                                {/*{*/}
                                                {/*    auth.user.is_reseller_account == 0 ?*/}
                                                {/*        <Link className="dropdown-item" to="/reseller-account"><span*/}
                                                {/*            className="mr-2"><i className="fas fa-user"/></span> Reseller*/}
                                                {/*            Account</Link>*/}
                                                {/*    :''*/}
                                                {/*}*/}
                                            </>
                                            : ''
                                    }
                                    <Link className="dropdown-item" to="/help-and-support"><span className="mr-2"><i
                                        className="fas fa-headset" /></span> Help &amp; Support</Link>
                                    <div className="dropdown-divider" />
                                    <Link className="dropdown-item" onClick={(e) => logout(e)} to="logout"><span
                                        className="mr-2"><i className="fas fa-sign-out-alt" /></span> Sign Out</Link>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar
