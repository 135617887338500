import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { addClientTeam } from "../../store/actions/authAction";
import Alert from "../alert/alert";
import { NavLink } from "react-router-dom";

function TeamAddc() {
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        type: 'team_member'
    });
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });

        if (e.target.name === 'password') {
            validatePassword(e.target.value);
        }
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasNumber = /\d/;
        const hasSpecialChar = /[!@#$%^&*]/;

        if (password.length < minLength) {
            setPasswordError('Password must be at least 8 characters long');
        } else if (!hasNumber.test(password)) {
            setPasswordError('Password must contain at least one number');
        } else if (!hasSpecialChar.test(password)) {
            setPasswordError('Password must contain at least one special character');
        } else {
            setPasswordError('');
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (passwordError) return;
        setLoader(true);
        dispatch(addClientTeam(user, setLoader));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><NavLink to="#">Account Management</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="myaccount-sec">
                <div className="container">
                    <Alert />
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="personal-heading">
                                <h5>Add Account</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 d-flex justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <form className="personal-box" method="post" onSubmit={onFormSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small id="accountTypeHelp" className="form-text text-muted">Account Type</small>
                                            <select className="form-control" name="type" required onChange={onInputChange}>
                                                <option value="team_member">Team Member</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small id="nameHelp" className="form-text text-muted">Account Name</small>
                                            <input type="text" className="form-control" placeholder="Enter Name" required name="name" onChange={onInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <small id="emailHelp" className="form-text text-muted">Account Email</small>
                                            <input type="email" className="form-control" placeholder="Enter Email" required name="email" onChange={onInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <small id="passwordHelp" className="form-text text-muted">Account Password</small>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Enter Password" required name="password" onChange={onInputChange} />
                                                <i onClick={() => togglePasswordVisibility('new_password')} className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`} />
                                            </div>

                                        </div>
                                        {passwordError && <small className="text-danger">{passwordError}</small>}
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <div className="update">
                                            <button type="submit" className="button" disabled={loader || passwordError}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamAddc;
