import React, { useState } from "react";
import LoginLogo from "../images/loginlogo.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../store/actions/authAction";
import Alert from "./alert/alert";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { appName } from "../global/global";

const RestPassword = ({ location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [user, setUser] = useState({
        password: "",
        confirm_password: "",
        password_token: queryString.parse(location.search).token,
    });
    const brandName = useSelector(state => state.rebrand.data);

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
        setError("");
    };

    const validatePassword = () => {
        const { password, confirm_password } = user;
        if (password.length < 8) {
            return "Password must be at least 8 characters long.";
        }
        if (!/[A-Z]/.test(password)) {
            return "Password must contain at least one uppercase letter.";
        }
        if (!/[a-z]/.test(password)) {
            return "Password must contain at least one lowercase letter.";
        }
        if (!/[0-9]/.test(password)) {
            return "Password must contain at least one number.";
        }
        if (password !== confirm_password) {
            return "Passwords do not match.";
        }
        return "";
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        const validationError = validatePassword();
        if (validationError) {
            setLoader(false);
            setError(validationError);
            return;
        }

        dispatch(resetPassword(user, setLoader, history));
    };

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Reset Password</title>
            </Helmet>
            <section className="login-sec">
                <div className="container">
                    <Alert />
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="login">
                                <div className="row">
                                    <div className="col-lg-5 col-md-12 d-flex align-items-center">
                                        <div className="login-content">
                                            <div className="logo-img-line">
                                                <img src={LoginLogo} alt className="img-fluid" />
                                            </div>
                                            <div className="logo-head text-white">
                                                <h5>Professional Agency Business <span className="d-lg-block">FOR You With just Minutes of work</span></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 offset-lg-1 col-md-12">
                                        <div className="login-account">
                                            <form method="post" onSubmit={onFormSubmit}>
                                                <h5 className="text-center">Reset Password</h5>
                                                {error && <div className="alert alert-danger">{error}</div>}
                                                <div className="form-group mt-5">
                                                    <label htmlFor="password">New Password</label>
                                                    <input type="password" className="form-control" id="password" placeholder="*********"
                                                        name="password" required onChange={onInputChange} />
                                                </div>
                                                <div className="form-group mt-5">
                                                    <label htmlFor="confirm_password">Confirm Password</label>
                                                    <input type="password" className="form-control" id="confirm_password" placeholder="*********"
                                                        name="confirm_password" required onChange={onInputChange} />
                                                </div>
                                                <div className="forget-sec m-4 text-center">
                                                    <h6>Login? <span><Link to="login">Click here</Link></span> </h6>
                                                </div>
                                                <button type="submit" className="button" disabled={loader}>
                                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RestPassword;
