import React from 'react'
import Aclients from './SubComponent/Aclients'

import Navbar from './SubComponent/Navbar'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global';
import { useSelector } from 'react-redux';


function AClinent() {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Clients </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <Aclients />
                {/*<Aclientcard/>*/}
            </div>
        </>
    )
}

export default AClinent
