import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import textStream from '../../images/default_txt.png';
import imageStream from '../../images/default_img.png';
import videoStream from '../../images/default_video.png';
import de from "../../images/de.png";
import e from "../../images/e.png";
import {deleteStream, fetchStream} from "../../store/actions/streamAction";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactTooltip from "react-tooltip";

function StreamHead() {

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const streamSelector = useSelector(state => state.stream);

  const [streamCount, setStreamCount] = useState(streamSelector.streams.length);
  const [q, setQ] = useState('');
  const [cat, setCat] = useState('');

  const onCategoryChange = (e) => {
    setQ(e.target.value)
  }

  const getQ = (e) => {
    setQ(e.target.value)
  };

  const search = (users) => {
    if(q !== "all") {
      return users.filter(
          user => user.title.toLowerCase().indexOf(q.toLowerCase()) > -1 || user.type.toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    }else{
      return users
    }
  };


  const [stream,setStream] = useState(streamSelector.streams);

  const [deleteId,setDeleteId] = useState({
    id: null
  });
  const [button,setButton] = useState('Delete');
  const [showDelete, setShowDelete] = useState(false);

  const onConfirm = async (e) => {
    setButton('Please wait...');

    await dispatch(deleteStream(deleteId,setShowDelete,setButton));
  }

  const onCancel = (e) => {
    setShowDelete(false);
  }

  const onButtonClick = (e,id) => {
    e.preventDefault();

    setDeleteId({...deleteId, id: id});
    setShowDelete(true);
  }


  useEffect(()=>{
    setStream(streamSelector.streams);
    setStreamCount(streamSelector.streams.length);
  },[streamSelector.streams])

  useEffect(()=>{

    dispatch(fetchStream(auth.token));

  },[])


    return (
        <>
          <section className="sec-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 d-flex align-items-center">
                  <div className="sec-1-heading newline">
                    <ul className="list-inline m-0 d-flex">
                      <li><a href="#">My Streams</a></li>
                      <li className="ml-5"><a href="#">{streamCount < 9 ? '0'+streamCount+' Stream' : streamCount+' Streams'} </a></li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-3 sizeview col-md-6 d-flex justify-content-md-end justify-content-lg-start ">
                  <select className="mdb-select md-form" searchable="Search here.."
                          onChange={(e)=>onCategoryChange(e)}>
                    <option value="all">Category</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="text">Text</option>
                  </select>
                </div> */}
                <div className="col-lg-9 mobilestrem  col-md-12 mt-md-3 mt-lg-0  d-flex justify-content-lg-end ">
                  <div className="search-add d-flex align-items-center justify-content-around ">
                  <select className="mdb-select md-form mr-lg-3 mr-md-3" searchable="Search here.."
                          onChange={(e)=>onCategoryChange(e)}>
                    <option value="all">All</option>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                    <option value="text">Text</option>
                    <option value="formatted">Formatted Text</option>
                  </select>
                    <div className="search-container ">
                      <form>
                        <input type="text" placeholder="Search.." onChange={(e)=>getQ(e)} name="search" />
                        <button type="submit"><i className="fa fa-search" /></button>
                      </form>
                    </div>
                    <div className="add d-flex ml-md-2 ml-lg-0 align-items-center">
                      <Link to="create-stream" className="button">
                        <span><i className="fas fa-plus-circle mr-2" /></span>  Add Stream
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="stream">
            <div className="container">

              <div className="row mt-5">
                <div className="col-md-12">
                  <table className="table table-hover table-responsive-lg">
                    <thead className="theadbgcolor text-white">
                    <tr className="text-uppercase">
                      <th scope="col">Thumbnails</th>
                      <th scope="col">Title</th>
                      <th scope="col">Description</th>
                      <th scope="col">Tags</th>
                      <th scope="col" style={{minWidth:"135px"}}>Created Date</th>
                      <th scope="col">Action</th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                      search(stream).length > 0 ?
                          search(stream).map((item,index)=>{
                            return(
                                <tr key={index}>
                                  <th scope="row">
                                    {
                                      item.type === 'text' || item.type === "formatted" ?
                                          <img src={textStream} alt="thumbnail" className="img-fluid img-thumbnail" width="150px" />
                                      : ''
                                    }
                                    {
                                      item.type === 'image' ?
                                          <img
                                              src={Object.entries(item.stream).length !== 0 ? item.stream.thumbnails : imageStream}
                                              alt className="img-fluid img-thumbnail" width="150px"/>
                                      : ''
                                    }
                                    {
                                      item.type === 'video' ?
                                          <img
                                              src={Object.entries(item.stream).length !== 0 ? item.stream.thumbnails : videoStream}
                                              alt className="img-fluid img-thumbnail" width="150px"/>
                                          : ''
                                    }
                                  </th>
                                  <td><h6 title={item.title} className="strem-title m-0" >{item.title}</h6></td>
                                  <td><h6 title={item.description} className="strem-description m-0">{item.description}</h6></td>
                                  <td><h6 title={item.tags} className="strem-tag m-0">
                                    {
                                      item.tags.split(',').map((value)=>(
                                          '#'+value.trim()+' '
                                      ))
                                    }
                                    </h6>
                                  </td>
                                  <td><h6 title={item.created} >{item.created}</h6></td>
                                  <td>
                                    <div className="tag-box">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="delete-edit d-flex justify-content-center">
                                           
                                            <div className="editimg custom-edit-btn text-center mr-2">
                                              <Link to={`edit-stream/${item.id}`}>
                                                <span   data-iscapture="true"  data-tip="Edit" data-for="mainTooltip" ><i class="fas fa-pencil-alt"></i></span>
                                                <ReactTooltip
                                                  id="mainTooltip"
                                                  place="top"
                                                  multiline={false}
                         
                                                 />
                                              </Link>
                                            </div>
                                            <div className="editimg custom-delete-btn text-center ">
                                              <a onClick={(e)=>onButtonClick(e,item.id)} href="#">
                                                <span   data-iscapture="true"  data-tip="Delete"  data-for="mainTooltip"  ><i class="far fa-trash-alt"></i></span>
                                                <ReactTooltip
                                                  id="mainTooltip"
                                                  place="top"
                                                  multiline={false}
                         
                                                 />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                            )
                          })
                          : <tr align="center">
                              <td colSpan="6">You have not created any content stream yet. Click 'Add Stream' to create one</td>
                             </tr>
                    }
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </section>

          <SweetAlert
              warning
              showCancel
              confirmBtnText={button}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="light"
              title="Are you sure?"
              onConfirm={(e)=>onConfirm(e)}
              onCancel={(e)=>onCancel(e)}
              focusCancelBtn
              show={showDelete}
          >
            Do you want to delete this stream
          </SweetAlert>
   
        </>
    )
}

export default StreamHead;
