import React from "react";
import Cstream from './SubComponent/Cstream'
import Cstreamfrom from './SubComponent/Cstreamfrom'
import Cstreamtable from './SubComponent/Cstreamtable'
import Navbar from './SubComponent/Navbar'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from "../global/global";
import { useSelector } from "react-redux";

const EditStream = (props) => {
  const brandName = useSelector(state => state.rebrand.data);


  return (
    <>
      <Helmet>
        <title>{brandName !== false ? brandName.name : appName} | Content Streams </title>
      </Helmet>
      <div className="wrapper-box">
        <Navbar />
        <BannerLinebar />
        <Cstream />
        <Cstreamfrom id={props} />
        <Cstreamtable id={props} />
      </div>
    </>
  )

}

export default EditStream;