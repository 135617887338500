import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateMemModal from './CreateMemModal';
import { LuFileEdit, LuMoveDownRight } from 'react-icons/lu';
import { FiTrash2 } from 'react-icons/fi';
import { FaEdit, FaRegCopy } from 'react-icons/fa';
import CommonAlert from '../Common/CommonAlert';
import { onDeleteCampaign } from '../../store/actions/CommonActions';
import { setAlert } from '../../store/actions/alert';
const MemberRows = ({ curElem, fetchMemberships, memberships, setMemberships }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id,
        })
    }
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id,

        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-membership", data, memberships, setMemberships, setSweet))
    }

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(curElem.domain);
            dispatch(setAlert("Payment URL copied successfully", "success"))
        } catch (err) {
            dispatch(setAlert(err, "danger"))
        }

    }

    return (
        <tr>
            <td>{curElem.name}</td>
            <td>{curElem.payment.price}</td>
            <td className="text-end">
                <span className="white-table-icon"><FaEdit title='Edit' style={{ cursor: "pointer" }} onClick={handleShow} /></span>
                <span className="white-table-icon"><FaRegCopy title='Payment URL' style={{ cursor: "pointer" }} onClick={handleCopy} /></span>
                <span className="white-table-icon"><FiTrash2 title='Delete' style={{ cursor: "pointer" }} onClick={onDelete} /></span>
            </td>

            <CreateMemModal
                show={show}
                handleClose={handleClose}
                fetchMemberships={fetchMemberships}
                curElem={curElem}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this membership?"}
                confirmButtonColor={""}
                icon={"delete"}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default MemberRows