import React, { useEffect, useState } from "react";
import Navbar from "./SubComponent/Navbar";
import { Link, NavLink, useHistory } from "react-router-dom";
import Deshcontentcard from "./SubComponent/Deshcontentcard";
import axios from "axios";
import { appName, baseURL } from "../global/global";
import { useDispatch, useSelector } from "react-redux";
import { addImageContent, addVideoContent } from "../store/actions/contentsActions";
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";

const Contents = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const auth = useSelector(state => state.auth);
    const contentData = useSelector(state => state.content);

    const [contentType, setContentType] = useState({
        type: 'all'
    });

    const [q, setQ] = useState('');
    const brandName = useSelector(state => state.rebrand.data);

    const [videoData, setVideoData] = useState({ data: [] });
    const [imageData, setImageData] = useState({ data: [] });

    const changeContentType = (e) => {
        setContentType({ type: e.target.value });
    }

    const fetchContents = (type) => {
        setLoader(true);
        axios({
            method: 'POST',
            url: `${baseURL}${type}`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {

                if (contentType.type === 'image') {
                    setImageData({ ...imageData, data: res.data.data });
                    dispatch(addImageContent(auth.token));

                } else if (contentType.type === 'all') {
                    if (type === 'fetch-videos') {
                        setVideoData({ ...videoData, data: res.data.data });
                        dispatch(addVideoContent(auth.token));
                    } else {
                        setImageData({ ...imageData, data: res.data.data });
                        dispatch(addImageContent(auth.token));
                    }
                }
                else {
                    setVideoData({ ...videoData, data: res.data.data });
                    dispatch(addVideoContent(auth.token));
                }

            } else {
                console.log(res.data);
            }
            setLoader(false);

        }).catch(error => {
            console.log(error);
            setLoader(false);
        })
    }

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const searchImg = (users) => {
        return users.filter(
            user => user.name !== '' ? user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 : user
        );
    };

    const searchVideo = (users) => {
        return users.filter(
            user => user.name !== '' ? user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 : user
        );
    };

    useEffect(() => {

        if (contentType.type === 'image') {
            fetchContents('fetch-thumbnails');
        }
        else if (contentType.type === 'video') {
            fetchContents('fetch-videos');
        }
        else {
            fetchContents('fetch-thumbnails');
            fetchContents('fetch-videos');
        }

    }, [contentType.type]);

    useEffect(() => {
        setVideoData({ ...videoData, data: contentData.videos });
    }, [contentData.videos])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | My Contents </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <section className="sec-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 d-flex align-items-center">
                                <div className="sec-1-heading newline">
                                    <ul className="list-inline m-0 d-flex">
                                        <li><NavLink to="#">My Contents</NavLink></li>
                                        <li className="ml-5">
                                            {
                                                contentType.type === 'video' ? <Link to="/">{videoData.data.length} Contents</Link> : ''
                                            }
                                            {
                                                contentType.type === 'image' ? <Link to="/">{imageData.data.length} Contents</Link> : ''
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-8 d-flex align-items-center  justify-content-lg-end ">
                                <div className="search-add d-flex align-items-center ">
                                    <div className="search-container">
                                        <select className="form-control" onChange={(e) => changeContentType(e)}>
                                            <option value="all">All</option>
                                            <option value="video">Video</option>
                                            <option value="image">Image</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-8  d-flex justify-content-lg-end">
                                <div className="search-add d-flex align-items-center  ">
                                    <div className="search-container ">
                                        <form>
                                            <input type="text" placeholder="Search.." onChange={(e) => onSearch(e)} name="search" />
                                            <button type="submit"><i className="fa fa-search" /></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contents Data   */}
                <section className="deshborad-sec">
                    <div className="container">
                        {
                            contentType.type === 'all' ? <h5>Recently Created Videos</h5> : ''
                        }
                        <div className="row gy-3">
                            {
                                contentType.type === 'video' || contentType.type === 'all' ?
                                    videoData.data.length > 0 ?
                                        searchVideo(videoData.data).map((item, index) => {
                                            return (
                                                <div key={index} className="col-lg-3 mt-3 col-md-6" style={{ marginBottom: "2rem" }}>
                                                    <Deshcontentcard
                                                        type="video"
                                                        image={item.poster}
                                                        name={item.name}
                                                        download={item.video_path}
                                                        create={item.created}
                                                        id={item.id}
                                                        fetchContents={fetchContents}
                                                        status={item.status}
                                                        failed={item.failed}
                                                    />
                                                </div>
                                            )
                                        })
                                        : <div className="col-md-12">
                                            <div className="video-imgages">
                                                {
                                                    contentType.type === "all" ? <h5>You have not created any Videos yet</h5> : ''
                                                }
                                                <ul className="d-flex list-inline">
                                                    <li><Link className="button" to="create-video">Create Video</Link></li>
                                                    {
                                                        contentType.type !== "all" ? <li><Link className="button" to="/image-creator">Create Images</Link></li> : ''
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                    : ''
                            }
                        </div>
                        {
                            contentType.type === 'all' ? <h5>Recently Created Images</h5> : ''
                        }
                        <div className="row gy-3">
                            {
                                contentType.type === 'image' || contentType.type === 'all' ?
                                    imageData.data.length > 0 ?
                                        searchImg(imageData.data).map((item, index) => {
                                            return (
                                                <div key={index} className="col-lg-3 col-md-6 mt-3" style={{ marginBottom: "2rem" }}>
                                                    <Deshcontentcard
                                                        type="image"
                                                        image={item.thumbnail}
                                                        name={item.name}
                                                        download={item.thumbnail}
                                                        create={item.created}
                                                        id={item.id}
                                                        fetchContents={fetchContents}
                                                        status=""
                                                        failed=""
                                                    />
                                                </div>
                                            )
                                        })
                                        : <div className="col-md-12">
                                            <div className="video-imgages">
                                                {
                                                    contentType.type === "all" ? <h5>You have not created any Image yet</h5> : ''
                                                }
                                                <ul className="d-flex list-inline">
                                                    {
                                                        contentType.type !== "all" ? <li><Link className="button" to="/create-video">Create Video</Link></li> : ''
                                                    }
                                                    <li><Link className="button" to="/image-creator">Create Images</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    : ''
                            }
                            {
                                loader === true ?
                                    <div className="loaderbox"><div className="loader"></div></div>
                                    : ''
                            }
                        </div>




                    </div>
                </section>
                {/* Contents Data End  */}
            </div>
        </>
    )
}

export default Contents;