import React, { useEffect } from 'react'
import ImageCreatorHead from './SubComponent/ImageCreatorHead'
import ImageEditor from './SubComponent/ImageEditor'
import Navbar from './SubComponent/Navbar'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { appName } from '../global/global';
import { useSelector } from 'react-redux';

function ImageCreator({ location }) {
  const brandName = useSelector(state => state.rebrand.data);


  return (
    <>
      <Helmet>
        <title>{brandName !== false ? brandName.name : appName} | Image Creator </title>
      </Helmet>
      <div className="wrapper-box">
        <Navbar />
        <BannerLinebar />
        <ImageCreatorHead location={location} />
        <ImageEditor location={location} />
      </div>
    </>
  )
}

export default ImageCreator
