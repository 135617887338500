import React, {useEffect, useState} from 'react'
import edit from '../../images/edit.png';
import download from '../../images/download.png';
import publish from '../../images/publish.png';

import imageStream from '../../images/default_img.png';
import videoStream from '../../images/default_video.png';
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../store/actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";
import {Link} from "react-router-dom";
import {addVideoContent} from "../../store/actions/contentsActions";
import Alert from "../alert/alert";

function Deshcontentcard(props) {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const contents = useSelector(state => state.content);
    const streamsData = useSelector(state => state.stream.streams);

    const [videoStreams, setVideoStreams] = useState(streamsData.filter(item => item.type === "video"));
    const [imageStreams, setImageStreams] = useState(streamsData.filter(item => item.type === "image"));

    const [deleteId, setDeleteId] = useState({
        id: null,
        type: null
    });

    const [pushStream, setPushStream] = useState({
        content_stream_id: null,
        title: null,
        description: null,
        thumbnail: null,
        url: null
    })

    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showImgPopup, setShowImgPopup] = useState(false);
    const [loadVideo, setLoadVideo] = useState(false);
    const [loadImage, setLoadImage] = useState(false);


    const onConfirm = () => {

        let url = 'delete-video';
        let fetch = 'fetch-videos';
        if (deleteId.type === 'image') {
            url = 'delete-thumbnail';
            fetch = 'fetch-thumbnails';
        }

        axios({
            method: 'POST',
            url: `${baseURL}${url}`,
            data: JSON.stringify({id: deleteId.id}),
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {

            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                props.fetchContents(fetch);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);

        }).catch(error => {
            console.log(error);
        })
    }

    const deleteCampaign = (id, type) => {
        setDeleteId({...deleteId, id: id, type: type});

        setShowDelete(true);
    }

    const pushImage = (id, data) => {
        setShowImgPopup(true);

        setPushStream({
            ...pushStream,
            thumbnail: data.image,
            url: data.download
        })
    }

    const pushVideo = (id, data) => {
        setShowPopup(true);

        setPushStream({
            ...pushStream,
            thumbnail: data.image,
            url: data.download
        })
    }

    const onSelectStream = (e) => {
        let data = e.target.value.split('__');

        setPushStream({
            ...pushStream,
            content_stream_id: data[0],
            title: data[1],
            description: data[2]
        })
    }

    const onFormSubmit = (e, type) => {
        e.preventDefault();

        if(type === 'video'){
            setLoadVideo(true);
        }else{
            setLoadImage(true);
        }

        axios({
            method: 'POST',
            url: `${baseURL}push-to-content-stream`,
            data: pushStream,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }

            if(type === 'video'){
                setLoadVideo(true);
                setShowPopup(false);
            }else{
                setLoadImage(true);
                setShowImgPopup(false);
            }

        }).catch(err=>{
            dispatch(setAlert('Server error, Please Contact Support','danger'));
        })
    }


    useEffect(() => {

        if (props.type === 'video') {
            let intr = setInterval(() => {
                let renderVideos = contents.videos.filter(content => content.status === "5" && content.failed === "0");

                if (renderVideos.length > 0) {

                    axios({
                        method: 'POST',
                        url: `${baseURL}get-video-progress`,
                        data: JSON.stringify({id: renderVideos[0].id}),
                        headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
                    }).then(res => {

                        if (res.data.data.status === "6" || res.data.data.failed === "1") {
                            // clearInterval(intr);
                            dispatch(addVideoContent(auth.token,intr));
                            console.log(res.data.data.status);
                        }

                    }).catch(error => {
                        clearInterval(intr);
                    })

                } else {
                    clearInterval(intr);
                }


            },5000)
        }

    }, [contents.videos])

    return (
        <>
            <Alert/>
            <div className="deshboard-part">
            <div className="deshborad-box mydeshborad-box image">

                {
                    props.type === 'image' ?
                        <img className="proj-img" src={props.image !== '' ? props.image : imageStream} width="100%"
                             height="100%"/>
                        : ''
                }
                {
                    props.type === 'video' ?
                        <img className="proj-img" src={props.image !== '' ? props.image : videoStream} width="100%"
                             height="100%"/>
                        : ''
                }
                <div className="overlay" style={{textAlign: "center"}}>
                    {
                        props.type === 'video' ?
                            props.status === "3" ?
                                <span className="badge badge-pill badge-primary">Awaiting Customization</span> : ''
                            : ''
                    }
                    {
                        props.type === 'video' ?
                            props.status === "5" && props.failed === "0" ?
                                <span className="badge badge-pill badge-primary">Rendering...</span> : ''
                            : ''
                    }
                    {
                        props.type === 'video' ?
                            props.status === "4" ?
                                <span className="badge badge-pill badge-warning">Downloading Resources...</span> : ''
                            : ''
                    }
                    {
                        props.type === 'video' ?
                            props.failed === "1" ?
                                <span className="badge badge-pill badge-danger text-white">Failed</span> : ''
                            : ''
                    }
                    <div className="icon">

                        <ul className="list-inline iconcenter d-flex">
                            <li className="text-center">
                                {
                                    props.type === 'image' ?
                                        <Link to={`image-creator?id=${props.id}`}>
                                            <span><img src={edit} alt className="img-fluid"/></span>
                                            <h6>Edit</h6>
                                        </Link>
                                        : ''
                                }
                                {
                                    props.status === "5" ? '' :
                                        props.type === 'video' ?
                                            <Link to={`create-video?id=${props.id}`}>
                                                <span><img src={edit} alt className="img-fluid"/></span>
                                                <h6>Edit</h6>
                                            </Link>
                                    : ''
                                }
                            </li>
                            {
                                props.download !== '' ?
                                    <li className="text-center">
                                        <a href={`${baseURL}download-file?url=${props.download}`}>
                                            <span><img src={download} alt className="img-fluid"/></span>
                                            <h6>Download</h6>
                                        </a>
                                    </li>
                                    : ''
                            }


                            {
                                props.type === 'video' ?
                                    props.status === "6" ?
                                        <li className="text-center">
                                            <a tabIndex="0"  onClick={(e) => pushVideo(props.id, props)}>
                                                <span style={{color:"#fff"}} ><i className="fas fa-upload"></i></span>
                                                <h6>Push</h6>
                                            </a>
                                        </li>
                                        : ''
                                    : ''
                            }

                            {
                                props.type === 'image' ?
                                    <li className="text-center">
                                        <a tabIndex="0" onClick={(e) => pushImage(props.id, props)}>
                                            <span style={{color:"#fff"}} ><i className="fas fa-upload"></i></span>
                                            <h6>Push</h6>
                                        </a>
                                    </li>
                                    : ''
                            }

                            <li className="text-center">
                                <a tabIndex="0" onClick={(e) => deleteCampaign(props.id, props.type)}>
                                    <span style={{color:"#fff", backgroundColor: "#f05359"}}><i
                                        class="far fa-trash-alt"></i></span>
                                    <h6>Delete</h6>
                                </a>
                            </li>

                        </ul>
                    </div>


                </div>
            </div>

            <div className="project-data">
                <ul className="list-inline m-0 d-flex justify-content-between">
                    <li title={props.name}>{props.name}</li>
                    <li title={props.create}>{props.create}</li>
                </ul>
            </div>
            </div>

            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e,'video')}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Push to Content Stream</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Select Content Stream</label>
                                    <select className="form-control" onChange={(e)=>onSelectStream(e)}>
                                        <option value selected disabled>--Select--</option>
                                        {
                                            videoStreams.length > 0 ?
                                                videoStreams.map((item,index)=>{
                                                    return(
                                                        <option key={index} value={`${item.id}__${item.title}__${item.description}`} >{item.title}</option>
                                                    )
                                                })
                                            : ''
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loadVideo}>
                                { loadVideo ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Push
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className={`modal ${showImgPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e,'image')}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Push to Content Stream</h5>
                            <button type="button" className="close" onClick={(e)=>setShowImgPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Select Content Stream</label>
                                    <select className="form-control" onChange={(e)=>onSelectStream(e)}>
                                        <option value selected disabled>--Select--</option>
                                        {
                                            imageStreams.length > 0 ?
                                                imageStreams.map((item,index)=>{
                                                    return(
                                                        <option key={index} value={`${item.id}__${item.title}__${item.description}`} >{item.title}</option>
                                                    )
                                                })
                                                : ''
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loadImage}>
                                { loadImage ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Push
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this content
            </SweetAlert>

        </>
    )
}

export default Deshcontentcard
