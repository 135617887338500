import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import {DragDropContext, Draggable,Droppable}  from "react-beautiful-dnd";
import {
    deleteUserStream,
    fetchUserStream,
    updateFileSequence,
    updateStreamAction
} from "../../store/actions/streamAction";

import thumbnail from "../../images/default_txt.png";
import img from "../../images/img.png";
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateFromHTML } from "draft-js-import-html";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

function Cstreamtable(props) {

    const auth = useSelector(state => state.auth);
    const streamData = useSelector(state => state.stream);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [streams, setStreams] = useState([]);
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState({
        id: null
    });

    const [streamType, setStreamType] = useState('');
    const [contentData, setContentData] = useState([]);

    const [updateStream, setUpdateStream] = useState({
        id: '',
        title: '',
        description: ''
    })

    const onConfirm = (e) => {
        setButton('Please wait...');

        dispatch(deleteUserStream(deleteId,setShowDelete));
    }

    const onCancel = (e) =>{
        setShowDelete(false);
    }

    const onButtonClick = (e,id) =>{
        e.preventDefault();
        setShowDelete(true);

        setDeleteId({id: id});
        setButton('Delete');
    }

    const openInputPopup = async (e,stream) => {
        e.preventDefault();
        setCheckEdit(false);

        await setInput(stream);
        setShowPopup(true);
    }

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [checkEdit, setCheckEdit] = useState(false);

    const setInput = (stream) => {
        setUpdateStream({
            ...updateStream,
            id: stream.id,
            title: stream.title,
            description: stream.description
        });

        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(stream.description)
                )
            )
        )
    }

    const onInputChange = (e) =>{
        setUpdateStream({...updateStream,[e.target.name]: e.target.value});
    }

    const onInputEditorData = (e) =>{
        setCheckEdit(true);

        setUpdateStream({...updateStream,description: draftToHtml(e)});

        setEditorState(
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(draftToHtml(e))
                )
            )
        )
    }

    const onFormSubmit = async (e) =>{
        e.preventDefault();
        setLoader(true);

        await dispatch(updateStreamAction(updateStream,setLoader,setShowPopup));
    }

    const onSetRecorder = (result) =>{
        if (!result.destination) {
            return;
        }

        const items = reorder(
            streams,
            result.source.index,
            result.destination.index
        );


        /** Send Request for change source file sequence */
        dispatch(updateFileSequence(items));

        setStreams(items);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(()=>{
        if(Object.entries(props).length !== 0){
            const params = props.id.match.params;

            let us = streamData.user_stream.filter(stream => stream.content_stream_id === params.id);
            let tmpType = streamData.streams.filter(stream => stream.id === params.id);

            setStreams(us);
            setStreamType(tmpType[0].type);
            setContentData(tmpType[0]);
        }
    },[streamData.user_stream]);

    useEffect(()=>{
        dispatch(fetchUserStream(auth.token));

        return () => {
            setStreams([]);
        }

    },[])

    useEffect(()=>{
        if(Object.entries(props).length !== 0) {
            const params = props.id.match.params;

            let tmpType2 = streamData.streams.filter(stream => stream.id === params.id);
            setContentData(tmpType2[0]);
        }
    },[streamData.streams])

    useEffect(()=>{
    },[updateStream]);


    return (
        <>
          <section className="file-stream-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="files-stream">
                    <h6>Files of this Stream:</h6>
                    <div className="schedule-table">
                      <table className="table table-striped table-responsive-lg table-hover mt-3">
                        <thead className="theadbgcolor text-white">
                          <tr>
                            <th scope="col">THUMBNAIL</th>
                            <th scope="col">TITLE</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col" style={{minWidth:"135px"}}>DATE</th>
                            <th scope="col">ACTION</th>
                          </tr>
                        </thead>
                          <DragDropContext onDragEnd={onSetRecorder}>
                              <Droppable droppableId="droppable">
                                  {(provided) => (
                                      <tbody
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                      >
                                      {
                                          streams.length > 0 ?
                                              streams.map((stream,index)=>{
                                                  return(
                                                      <Draggable key={stream.id} draggableId={`id_${stream.id}`} index={index}>
                                                          {(provided) => (
                                                              <tr
                                                                  ref={provided.innerRef}
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                              >
                                                                  <td>
                                                                      {
                                                                          <img src={stream.thumbnails !== null ? stream.thumbnails : thumbnail} width="50px" alt="thumbnail" />
                                                                      }
                                                                  </td>
                                                                  <td><h6 className="strem-title">{stream.title !== '' ? stream.title : contentData.title}</h6></td>
                                                                  <td><h6 className="strem-description">{stream.description !== '' ? stream.description : contentData.description}</h6></td>
                                                                  <td>{stream.created}</td>
                                                                  <td>
                                                                      <a onClick={(e)=>openInputPopup(e,stream)} href="#" style={{marginRight: "10px"}}>
                                                                          <i className="fas fa-edit"></i>
                                                                      </a>
                                                                      <a onClick={(e)=>onButtonClick(e,stream.id)} href="#" style={{color:"red"}} >
                                                                          <i className="fas fa-trash-alt"></i>
                                                                      </a>
                                                                  </td>

                                                              </tr>
                                                          )}
                                                      </Draggable>
                                                  )
                                              })
                                              : <tr className="table-light" align="center">
                                                  <td colSpan="5">No Records Found</td>
                                              </tr>
                                      }
                                          {provided.placeholder}
                                      </tbody>
                                  )}
                              </Droppable>
                          </DragDropContext>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{padding: "15px 20px"}} method="post" onSubmit={(e)=>onFormSubmit(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">File Details</h5>
                            <button type="button" className="close" onClick={(e)=>setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Title</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           aria-describedby="emailHelp" placeholder="Enter Stream Title" required
                                           defaultValue={updateStream.title == 'null' ? '' : updateStream.title}
                                           name="title" onChange={(e)=> onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Description</label>
                                    {
                                        streamType === 'formatted' ?
                                            <>
                                                {/*<CKEditor initData={updateStream.description} />*/}
                                                {
                                                    checkEdit ?
                                                        <Editor
                                                            defaultEditorState={editorState}
                                                            // editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onContentStateChange={(e) => onInputEditorData(e)}
                                                        />
                                                    : <Editor
                                                            // defaultEditorState={editorState}
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onContentStateChange={(e) => onInputEditorData(e)}
                                                        />
                                                }
                                            </>
                                            :
                                            <textarea className="form-control" id="exampleInputEmail1"
                                                      aria-describedby="emailHelp" placeholder="Enter Stream Description" required
                                                      defaultValue={updateStream.description == 'null' ? '' : updateStream.description }
                                                      name="description" onChange={(e)=> onInputChange(e)}
                                            ></textarea>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/> : '' } Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to Delete this File
            </SweetAlert>

        </>
    )
}

export default Cstreamtable;
