import React, { useEffect, useState } from "react";
import downloads from "../../images/downloads.png";
import { useDispatch, useSelector } from "react-redux";
import {
    removeBgLayer, removeBlurLayer,
    updateColorLayer,
    updateImage,
    updateLayerDetails,
    updateSizeLayer, updateSlideOpacity, updateSlideTime,
    updateTextLayer
} from "../../store/actions/videoAction";
import MediaSearchVideo from "./MediaSearchVideo";
import axios from "axios";
import { baseURL } from "../../global/global";
import { SketchPicker } from "react-color";
import WebFont from "webfontloader";
import $ from "jquery"
import swal from "sweetalert";
import QuoteVideo from "./QuoteVideo";
import ViralVideo from "./ViralVideo";
import rgbHex from "rgb-hex";


const CustomizeVideo = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const auth = useSelector(state => state.auth);
    const textStateData = useSelector(state => state.videoReducer);
    let selectedIndex = textStateData.tiles.findIndex(({ is_selected }) => is_selected === "true");
    let selectedLayer = textStateData.tiles.find(({ is_selected }) => is_selected === "true");

    const [uploadedFiles, setUploadedFiles] = useState({ data: [] })
    const [fontData, setFontData] = useState({ data: [] });
    const [googleFonts, setGoogleFonts] = useState({ data: [] });

    const [removeBg, setRemoveBg] = useState(false)
    const [pageNum, setPageNum] = useState(1);
    const [blurStatus, setBlurStatus] = useState(false);

    const [loadFont, setLoadFont] = useState(false);
    const [showTextColor, setShowTextColor] = useState(false);
    const [showBgColor, setShowBgColor] = useState(false);

    const onInputText = (e) => {
        dispatch(updateTextLayer(selectedIndex, e.target.value));
    }

    const onInputColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`

        dispatch(updateColorLayer(selectedIndex, hex));
    }

    const onSizeInput = (e) => {
        dispatch(updateSizeLayer(selectedIndex, e.target.value));
    }

    const onBackgroundChange = (e) => {
        let data = {
            index: selectedIndex,
            value: e.hex
        }
        setRemoveBg(false);

        dispatch(updateLayerDetails('UPDATE_TEXT_BG_LAYER', data));
    }

    const onPositionChange = (type) => {
        let data = {
            index: selectedIndex,
            value: type
        }

        dispatch(updateLayerDetails('UPDATE_TEXT_POSITION_LAYER', data));
    }

    const onFontChange = (e) => {

        $(".menu").removeClass("showMenu");

        let data = {
            index: selectedIndex,
            value: e.split('__')[0],
            url: e.split('__')[1]
        }

        dispatch(updateLayerDetails('UPDATE_TEXT_FONT_LAYER', data));
    }

    const changeOpacity = (e) => {

        dispatch(updateSlideOpacity(selectedIndex, e.target.value));
    }

    const onImageUpload = (e) => {

        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {

            if (e.target.files[0].size < 5000000) {
                setLoader(true);

                let formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('file_type', 'image');

                axios({
                    method: 'POST',
                    url: `${baseURL}upload-file`,
                    data: formData,
                    headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
                }).then(res => {

                    if (res.data.status === true) {
                        let arr = uploadedFiles.data;
                        arr.push(res.data.data)

                        setUploadedFiles({ ...uploadedFiles, data: arr });
                    }
                    setLoader(false);

                }).catch(error => {
                    console.log(error);
                })
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }

        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const selectImage = (url) => {
        dispatch(updateImage(selectedIndex, url));
    }

    const removeBackground = (e) => {
        if (removeBg === false) {
            setRemoveBg(true);
            dispatch(removeBgLayer(selectedIndex))
        } else {
            setRemoveBg(false);
        }
    }

    const searchFonts = () => {
        setLoadFont(true);
        axios({
            method: 'POST',
            url: `${baseURL}search-media`,
            data: JSON.stringify({ search_type: "font", keyword: "", page: pageNum }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                setFontData({ ...fontData, data: fontData.data.concat(res.data.data) });

                let arr = res.data.data.map((item, index) => {
                    return (
                        item.family
                    )
                })

                setGoogleFonts({ ...googleFonts, data: googleFonts.data.concat(arr) });
            }
            setPageNum(pageNum + 1);
            setLoadFont(false);

        }).catch(error => {
            console.log(error);
        })
    }

    const addClipTime = (e) => {
        dispatch(updateSlideTime(selectedIndex, e.target.value));
    }

    const addBlur = (e) => {
        dispatch(removeBlurLayer(selectedIndex, blurStatus === false ? true : false));

        if (blurStatus === true) {
            setBlurStatus(false);
        } else {
            setBlurStatus(true);
        }
    }

    useEffect(() => {
        searchFonts();

        return () => {
            setFontData({ ...fontData, data: [] });
            setUploadedFiles({ ...uploadedFiles, data: [] });
            setGoogleFonts({ ...googleFonts, data: [] });
        }

    }, []);

    useEffect(() => {
        $(".dropdownbox").click(function () {
            $(".menu").toggleClass("showMenu");
            $(".menu > li").click(function () {
                console.log($(this).text(), 'iuywe');
                if ($(this).text() != ' Load More') {
                    $(".dropdownbox > p").text($(this).text());
                    $(".menu").removeClass("showMenu");
                }
            });
        });
    }, [])

    useEffect(() => {
        if (googleFonts.data.length > 0) {
            WebFont.load({
                google: {
                    families: googleFonts.data
                }
            });
        }
    }, [googleFonts.data])

    return (
        <>
            <div className="create-video-box mt-5">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active button" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Clips</a>
                                    <a className="nav-link button" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Text &amp; Position</a>
                                    <a className="nav-link button" id="v-pills-quotes-tab" data-toggle="pill" href="#v-pills-quotes" role="tab" aria-controls="v-pills-quotes" aria-selected="false">Quotes</a>
                                    <a className="nav-link button" id="v-pills-viral-tab" data-toggle="pill" href="#v-pills-viral" role="tab" aria-controls="v-pills-viral" aria-selected="false">Viral Videos</a>
                                    <a className="nav-link button" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Upload</a>
                                    <a className="nav-link button" id="v-pills-library-tab" data-toggle="pill" href="#v-pills-library" role="tab" aria-controls="v-pills-library" aria-selected="false">Library</a>
                                </div>
                            </div>
                            <div className="col-md-8  linebar col-lg-8 p-0">
                                <div className="tab-content" id="v-pills-tabContent" style={{ width: "90%", margin: "0 auto" }}>
                                    <div className="tab-pane mt-2 fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <div className="slide-setting">
                                            <p className="text-left ml-3" >Slide time (Seconds)</p>
                                            <div className="col-md-3">
                                                <div className="input-group spinner0">
                                                    <input
                                                        type="number"
                                                        className="form-control slide-time-quote "
                                                        value={selectedLayer.duration}
                                                        min={1}
                                                        onChange={(e) => addClipTime(e)}
                                                    />
                                                    {/* <div className="input-group-btn-vertical">
                                                        <button className="btn btn-default btn-start" type="button">
                                                            <i className="fa fa-caret-up" style={{transform: 'translate(0px, -5px)'}} />
                                                        </button>
                                                        <button className="btn btn-default btn-start" type="button">
                                                            <i className="fa fa-caret-down" style={{transform: 'translate(0px, -5px)'}} />
                                                        </button>
                                                    </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane mt-2 fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <div className="text-position">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="textarea-box">
                                                        <div className="form-group text-left">
                                                            <label>Text (Avoid Using Special Characters)</label>
                                                            <textarea
                                                                tabIndex="0"
                                                                className="form-control"
                                                                rows={5}
                                                                placeholder="Write a text"
                                                                value={selectedLayer.text}
                                                                onChange={(e) => onInputText(e)}
                                                            >{selectedLayer.text}</textarea>
                                                        </div>
                                                    </div>
                                                    <div className="font-style-box">
                                                        <div className=" text-left">
                                                            <label className="m-0" >Select Font</label>
                                                            <div className="wrapper">
                                                                <div className="dropdownbox">
                                                                    <p className="m-0">Font Style</p>
                                                                </div>
                                                                <ul className="menu list-inline">
                                                                    {
                                                                        fontData.data.length > 0 ?
                                                                            fontData.data.map((item, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <li
                                                                                            key={index}
                                                                                            onClick={(e) => onFontChange(item.family + '__' + item.url)}
                                                                                            style={{ fontFamily: item.family }}

                                                                                        >
                                                                                            {item.family}
                                                                                        </li>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            : ''
                                                                    }

                                                                    <li className="p-0 m-0" ><div className="divider "></div> </li>
                                                                    <li className="text-center">
                                                                        <a style={{ cursor: "pointer" }} onClick={(e) => searchFonts()} >
                                                                            {loadFont === true ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Load More
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="text-position-dropdown">
                                                        <label>Text Position</label>
                                                        <div className="box-wrapper">
                                                            <div onClick={(e) => onPositionChange('top-left')} className={`box top_left ${selectedLayer.text_position === 'top-left' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('top-center')} className={`box ${selectedLayer.text_position === 'top-center' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('top-right')} className={`box top_right ${selectedLayer.text_position === 'top-right' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('center-left')} className={`box ${selectedLayer.text_position === 'center-left' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('center')} className={`box ${selectedLayer.text_position === 'center' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('center-right')} className={`box ${selectedLayer.text_position === 'center-right' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('bottom-left')} className={`box bottom_left ${selectedLayer.text_position === 'bottom-left' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('bottom-center')} className={`box ${selectedLayer.text_position === 'bottom-center' ? 'text-pos-active' : ''}`}><span></span></div>
                                                            <div onClick={(e) => onPositionChange('bottom-right')} className={`box bottom_right ${selectedLayer.text_position === 'bottom-right' ? 'text-pos-active' : ''}`}><span></span></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row mt-3  color-backgroundcolor ">
                                                <div className="col-md-6">
                                                    <p className="m-0 text-left ">Text Color</p>
                                                    <div className="input-group   mt-2">
                                                        {
                                                            <>
                                                                <div className="swatch" onClick={(e) => setShowTextColor(true)}>
                                                                    <div className="swatch-color" style={{ backgroundColor: selectedLayer.text_color }} />
                                                                </div>
                                                                {showTextColor ? <div>
                                                                    <div className="swatch-cover" onClick={(e) => setShowTextColor(false)} />
                                                                    <SketchPicker color={selectedLayer.text_color} onChange={(e) => onInputColor(e)} />
                                                                </div> : null}
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <p className="m-0 text-left ">Text Background Color</p>
                                                    <div className="input-group mt-2">
                                                        {
                                                            <>
                                                                <div className="swatch" onClick={(e) => setShowBgColor(true)}>
                                                                    <div className="swatch-color" style={{ backgroundColor: selectedLayer.bg_color }} />
                                                                </div>
                                                                {showBgColor ? <div>
                                                                    <div className="swatch-cover" onClick={(e) => setShowBgColor(false)} />
                                                                    <SketchPicker color={selectedLayer.bg_color} onChange={(e) => onBackgroundChange(e)} />
                                                                </div> : null}
                                                            </>
                                                        }
                                                    </div>

                                                </div>

                                            </div>


                                            <div className="row opacity-font mt-3 ">
                                                <div className="col-md-6">
                                                    <div className="form-group text-left ">
                                                        <label htmlFor="font-size" className="active">
                                                            Text Background Opacity
                                                        </label>
                                                        <input type="number" onChange={(e) => changeOpacity(e)} step="0.1" min={0} max={1} className="form-control" disabled={selectedLayer.text !== '' ? false : true} defaultValue={1} />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group text-left ">
                                                        <label htmlFor="font-size" className="active"> Font Size </label>
                                                        <input type="number" step="1" min={1} max={100}
                                                            className="form-control" defaultValue={selectedLayer.text_size}
                                                            onChange={(e) => onSizeInput(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row blur-removetext mt-2">
                                                <div className="col-md-6">
                                                    <div className="form-group text-left">
                                                        <div className="form-check">
                                                            <input className="form-check-input" style={{ margin: "0", marginTop: "5px" }} type="checkbox" checked={blurStatus} id="gridCheck" onChange={(e) => addBlur(e)} disabled={selectedLayer.text !== '' ? false : true} />
                                                            <label className="form-check-label" htmlFor="gridCheck" style={{ marginLeft: "22px", }} >
                                                                Apply Blur Effect
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="form-check text-left">
                                                            <input className="form-check-input" id="gridCheck2" style={{ margin: "0", marginTop: "5px" }} type="checkbox" value={removeBg} checked={removeBg} disabled={selectedLayer.bg_color !== '' ? false : true} onChange={(e) => removeBackground(e)} />
                                                            <label className="form-check-label" htmlFor="gridCheck2" style={{ marginLeft: "22px", }} >Remove Text Background</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                        <div className="upload-box-video">
                                            <div className="row">
                                                <div className="col-md-8 mx-auto p-3 upload-border">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input"
                                                            id="inputGroupFile01"
                                                            onChange={(e) => onImageUpload(e)}
                                                            accept="image/*"
                                                        />
                                                        <label className="custom-file-label" htmlFor="inputGroupFile01">
                                                            Choose file
                                                        </label>
                                                    </div>

                                                    <span><img src={downloads} alt="download" /></span>
                                                    <p className="mt-3 upload-font">JPG, .PNG up to 5MB is allowed</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="img-box" style={{ maxHeight: "22vh", height:"auto", overflowY: "auto" }}>
                                                        <ul className="d-flex list-inline flex-wrap justify-content-center ">
                                                            {
                                                                uploadedFiles.data.length > 0 ?
                                                                    uploadedFiles.data.map((item, index) => {
                                                                        return (
                                                                            <li key={index} className="p-1" style={{ position: "relative", backgroundColor: "#a2a0a008", margin: "2px", width: "150px", height: "110px", cursor: "pointer" }} >
                                                                                <img width="100%" height="100%" src={item} onClick={(e) => selectImage(item)} alt="" style={{ objectFit: "cover" }} />
                                                                                <span className="delete-icon" style={{ color: "red", right: "3px", top: "0", position: "absolute" }}><i className="far fa-trash-alt"></i></span>

                                                                                <span className="checked-icon" style={{ color: "#000", top: "50%", left: "50%", position: "absolute" }}><i class="far fa-check-circle"></i></span>

                                                                            </li>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                            {
                                                                loader === true ?
                                                                    <h6 className="m-0" style={{ width: "100%", textAlign: "center" }} ><i style={{ fontSize: "40px", color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></h6>
                                                                    : ''
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade " id="v-pills-quotes" role="tabpanel" aria-labelledby="v-pills-quotes-tab">
                                        <QuoteVideo />
                                    </div>

                                    <div className="tab-pane fade " id="v-pills-viral" role="tabpanel" aria-labelledby="v-pills-viral-tab">
                                        <ViralVideo />
                                    </div>

                                    <div className="tab-pane fade " id="v-pills-library" role="tabpanel" aria-labelledby="v-pills-library-tab">
                                        <MediaSearchVideo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default CustomizeVideo;