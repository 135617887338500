import randomstring from "randomstring";

const initialState = {
    tiles: [{
        type: "image",
        text: "",
        id: randomstring.generate(),
        text_position: "bottom-center",
        text_color: "#ffffff",
        start_time: "1",
        duration: "5",
        text_size: "20",
        opacity: "1",
        bg_color: "",
        font: "",
        font_name: "Select Font",
        file_url: "",
        transition: "",
        blur: "false",
        remove_bg: "false",
        audio: "",
        tts_text: "",
        tts_gen: "",
        poster: "",
        is_selected: "true"
    }],
    logo: false,
    watermark: false,
    audio: false,
    thumbnail: false,
    type: 'save',
    name: 'agencyreel_' + randomstring.generate(8),
    id: false,
}

export default function (state = initialState, action) {

    switch (action.type) {

        case 'ADD_TEXT_LAYER':
            let array = state.tiles;
            array.push(action.payload);
            return {
                ...state,
                tiles: array
            }

        case 'UPDATE_TEXT_LAYER_DATA':
            console.log(action.payload, "dfgvbjdfjgvdjh")
            return {
                ...state,
                tiles: action.payload
            }

        case 'UPDATE_IMAGE_LAYER':
            let imageIndex = action.payload.id;
            state.tiles[imageIndex].type = action.payload.type;
            state.tiles[imageIndex].poster = action.payload.image;
            state.tiles[imageIndex].file_url = action.payload.image;
            return {
                ...state
            }

        case 'UPDATE_IMAGE_TYPE_LAYER':
            state.tiles[action.payload.id].type = action.payload.type;
            state.tiles[action.payload.id].poster = action.payload.image;
            state.tiles[action.payload.id].file_url = action.payload.file_url;
            return {
                ...state
            }

        case 'UPDATE_TEXT_LAYER':
            let textIndex = action.payload.id;
            state.tiles[textIndex].text = action.payload.text;
            return {
                ...state
            }

        case 'UPDATE_TEXT_COLOR_LAYER':
            let colorIndex = action.payload.id;
            state.tiles[colorIndex].text_color = action.payload.color;
            return {
                ...state
            }

        case 'UPDATE_BLUR_LAYER':
            state.tiles[action.payload.index].blur = action.payload.value;
            return {
                ...state
            }

        case 'UPDATE_TEXT_SIZE_LAYER':
            let sizeIndex = action.payload.id;
            state.tiles[sizeIndex].text_size = action.payload.size;
            return {
                ...state
            }

        case 'UPDATE_TEXT_BG_LAYER':
            state.tiles[action.payload.index].bg_color = action.payload.value;
            return {
                ...state
            }

        case 'UPDATE_TEXT_POSITION_LAYER':
            state.tiles[action.payload.index].text_position = action.payload.value;
            return {
                ...state
            }

        case 'UPDATE_TEXT_FONT_LAYER':
            state.tiles[action.payload.index].font = action.payload.url;
            state.tiles[action.payload.index].font_name = action.payload.value;
            return {
                ...state
            }

        case 'ADD_LOGO':
            return {
                ...state,
                logo: action.payload
            }

        case 'ADD_WATERMARK':
            return {
                ...state,
                watermark: action.payload
            }

        case 'UPDATE_LOGO_POSITION':
            state.logo.position = action.payload
            return {
                ...state,
            }

        case 'UPDATE_WATERMARK_POSITION':
            state.watermark.position = action.payload
            return {
                ...state,
            }

        case 'ADD_MUSIC_LAYER':
            return {
                ...state,
                audio: action.payload
            }

        case 'CHANGE_SELECTED_LAYER':
            let index = state.tiles.findIndex(({ is_selected }) => is_selected === "true");
            state.tiles[index].is_selected = "false";
            state.tiles[action.payload].is_selected = "true";
            return {
                ...state,
            }

        case 'ADD_VIDEO_DURATION':
            state.tiles[action.payload.index].duration = action.payload.value;
            return {
                ...state
            }

        case 'CHANGE_VIDEO_OPACITY_LAYER':
            state.tiles[action.payload.index].opacity = action.payload.value;
            return {
                ...state
            }

        case 'REMOVE_BACKGROUND_LAYER':
            state.tiles[action.payload.index].bg_color = "";
            return {
                ...state
            }

        case 'UPDATE_PROJECT_NAME':
            return {
                ...state,
                name: action.payload
            }

        case 'UPDATE_AUDIO_LAYER':
            state.tiles[action.payload.index].audio = action.payload.data;
            return {
                ...state
            }

        case 'REMOVE_LOGO_LAYER':
            return {
                ...state,
                logo: false
            }

        case 'REMOVE_WATERMARK_LAYER':
            return {
                ...state,
                watermark: false
            }

        case 'UPDATE_THUMBNAIL':
            return {
                ...state,
                thumbnail: action.payload.thumbnail
            }

        case 'UPDATE_RENDER_TYPE':
            return {
                ...state,
                type: action.payload
            }

        case 'UPDATE_TTS_TEXT':
            state.tiles[action.payload.index].tts_text = action.payload.value
            return {
                ...state
            }

        case 'UPDATE_TTS_GEN':
            state.tiles[action.payload.index].tts_gen = action.payload.value
            return {
                ...state
            }

        case 'UPDATE_TTS_GEN_STATUS':
            state.tiles[action.payload.index].tts_gen = ""
            return {
                ...state
            }

        case 'ADD_USER_KEYWORD':
            return {
                ...state,
                keyword: action.payload.keyword
            }

        case 'RESET_LAYER_DATA':
            return {
                tiles: action.payload.tiles,
                logo: action.payload.logo,
                watermark: action.payload.watermark,
                audio: action.payload.audio,
                thumbnail: action.payload.thumbnail,
                type: action.payload.type,
                name: action.payload.name,
                keyword: false
            }

        case 'UPDATE_ALL_LAYER_DATA':
            return {
                tiles: action.payload.json.tiles,
                logo: action.payload.json.logo,
                watermark: action.payload.json.watermark,
                audio: action.payload.json.audio,
                thumbnail: action.payload.json.thumbnail,
                type: action.payload.json.type,
                name: action.payload.json.name,
                id: action.payload.id,
                keyword: false
            }

        default:
            return state;
    }

}

