import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
    addTextLayer,
    changeSelected,
    updateImage,
    updateLayerDetails,
    updateThumbnail
} from "../../store/actions/videoAction";
import axios from "axios";
import { baseURL } from "../../global/global";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import defaultImg from '../../images/default_img.png';


const TilesVideo = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const tileData = useSelector(state => state.videoReducer);

    const [keywordTile, setKeywordTile] = useState(tileData.keyword);

    const keywordArray = ['nature', 'car', 'animal', 'water', 'birds', 'human', 'space', 'kids', 'water', 'fitness', 'dog', 'cat', 'food'];
    const [keyword, setKeyword] = useState(keywordArray[Math.floor(Math.random() * keywordArray.length)]);
    const [newImage, setNewImage] = useState([]);

    let selectedIndex = tileData.tiles.findIndex(({ is_selected }) => is_selected === "true");

    const deleteLayer = (e) => {
        console.log(e, "abbb")
        if (selectedIndex !== e) {
            tileData.tiles.splice(e, 1);

            dispatch(updateLayerDetails('UPDATE_TEXT_LAYER_DATA', tileData.tiles));
        }
    }

    const refreshLayer = async (e) => {
        await fetchMedia(tileData.keyword !== false ? tileData.keyword : keyword);
        let randomImage = newImage[Math.floor(Math.random() * keywordArray.length)].image;

        dispatch(updateImage(e, randomImage));
        if (e === 0) {
            dispatch(updateThumbnail(randomImage));
        }
    }

    const addTextSlide = async (e) => {
        await fetchMedia(tileData.keyword !== false ? tileData.keyword : keyword);
        let randomImage = newImage[Math.floor(Math.random() * keywordArray.length)].image;

        dispatch(addTextLayer(randomImage));
        dispatch(changeSelected(selectedIndex + 1));
    }

    const fetchMedia = (new_keyword = keyword) => {
        axios({
            method: 'POST',
            url: `${baseURL}search-media`,
            data: JSON.stringify({ search_type: "image", keyword: new_keyword, page: 1 }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setNewImage(res.data.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const updatePreview = (index) => {
        dispatch(changeSelected(index));
    }

    // Drag Tiles
    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            tileData.tiles,
            result.source.index,
            result.destination.index
        );


        /** Send Request for change source file sequence */
        dispatch(updateLayerDetails('UPDATE_TEXT_LAYER_DATA', items));
        dispatch(updateThumbnail(items[0].poster));
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        fetchMedia();

    }, []);

    useEffect(() => {
        if (tileData.keyword !== false) {
            setKeywordTile(tileData.keyword);
            fetchMedia(tileData.keyword)
        }
    }, [tileData.keyword])

    return (
        <>
            <div className="create-video-list plus-icon-scrollbar mt-5">
                <div className="row " style={{ height: "150px", overflowX: "scroll", overflowY: "hidden" }} >
                    <div className="col-lg-12 col-md-12">
                        <DragDropContext onDragEnd={onSetRecorder}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided) => (
                                    <ul
                                        className="list-inline d-flex  align-items-center"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            tileData.tiles.length > 0 ?
                                                tileData.tiles.map((item, index) => {
                                                    return (
                                                        <Draggable key={item.id} draggableId={`id_${item.id}`} index={index}>
                                                            {(provided) => (
                                                                <li ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    key={index}>
                                                                    <div className={`list-video ${selectedIndex === index ? 'active-tile' : ''}`}>
                                                                        <div className="list-vidoes">
                                                                            <div className="tilesvideosize" >
                                                                                <img onClick={(e) => updatePreview(index)} src={item.poster !== '' ? item.poster : defaultImg} alt="preview" className="img-fluid" style={{ borderRadius: '5px' }} />
                                                                            </div>
                                                                            <div className="overlay">
                                                                                <a onClick={(e) => refreshLayer(index)} className="icon" title="Change Slide">
                                                                                    <i className="fas fa-redo-alt" />
                                                                                </a>
                                                                                <a onClick={(e) => deleteLayer(index)} className="icon" title="Delete Slide">
                                                                                    <i className="far fa-trash-alt" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })
                                                : ''
                                        }
                                        <div tabIndex="0" className="plus-icon    playicon-tooltip  ml-4" style={{ cursor: 'pointer', marginTop: "10px" }}>

                                            <a onClick={(e) => addTextSlide(e)}><i className="fas fa-plus-circle" data-for="mainTooltip" data-iscapture="true" data-tip="Add Slide" />
                                                <ReactTooltip
                                                    id="mainTooltip"
                                                    place="right"
                                                    multiline={true}
                                                />
                                            </a>

                                        </div>
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </div>

                </div>
            </div>
        </>
    )
}

export default TilesVideo;