import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TimezoneSelect from 'react-timezone-select';
import { addSchedulerContent } from "../store/actions/schedularAction";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import TimeKeeper from "react-timekeeper";

import linkedInImage from '../images/linkedin_new.png';
import redditImage from '../images/reddit_new.png';
import vimeoImage from '../images/vimeo_new.png';
import twitterImage from '../images/twitter_new.png';
import youtubeImage from '../images/youtube_new.png';
import telegramImage from '../images/telegram_new.png';
import facebookImage from '../images/facebook_new.png';
import mediumImage from '../images/medium-logo.png';
import wordpressImage from "../images/wordpress-logo.png";
import SocialAccountType from "./SubComponent/SocialAccountType";
import textStream from "../images/default_txt.png";
import imageStream from "../images/default_img.png";
import videoStream from "../images/default_video.png";
import axios from "axios";
import { baseURL } from "../global/global";
import swal from "sweetalert";


function Popup({ inputDate, setShowPopup }) {

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const streamData = useSelector(state => state.stream);
  const socialData = useSelector(state => state.social.accounts);

  const [clients, setClients] = useState(auth.appClients);
  const [streams, setStreams] = useState(streamData.streams);
  const [socials, setSocials] = useState(socialData);
  const [loader, setLoader] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [time, setTime] = useState('12:00');
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [streamType, setStreamType] = useState(null);

  const [selectedTimezone, setSelectedTimezone] = useState({
    value: timezone,
    label: null
  });

  const [scheduleStream, setScheduleStream] = useState({
    user_id: auth.user.id,
    user_client_id: null,
    content_stream_id: null,
    social_accounts: [],
    schedule_date: "",
    schedule_time: "",
    hours: "01",
    minutes: "00",
    timezone: timezone,
    re_purpose: 0,
    schedule_type: inputDate.schedule_type
  });

  const [nextId, setNextId] = useState(1);

  const [engData, setEmgData] = useState({ data: [] });
  const onInputChange = (e, type = null) => {
    setStreamType(type);
    setScheduleStream({ ...scheduleStream, [e.target.name]: e.target.value })

    if (e.target.name === 'user_client_id') {
      fetchEngagementData(e.target.value);
    }

  }

  const fetchEngagementData = (id) => {
    axios({
      method: 'POST',
      url: `${baseURL}best-time`,
      data: JSON.stringify({ client_id: id }),
      headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
    }).then(res => {

      if (res.data.status === true) {
        setEmgData({ ...engData, data: res.data.data });
      } else {
        console.log(res);
      }

    }).catch(error => {
    })
  }

  const onTimeKeepChange = (e) => {
    let tmpTime = e.split(":");

    setScheduleStream({ ...scheduleStream, hours: tmpTime[0], minutes: tmpTime[1] });
  }

  const onInputSocial = (e) => {
    let array = scheduleStream.social_accounts;

    if (array.includes(e.target.value)) {
      array.splice(array.indexOf(e.target.value), 1);
    } else {
      array.push(e.target.value);
    }

    setScheduleStream({ ...scheduleStream, social_accounts: array });
  }

  const onCheckInput = (e) => {
    if (scheduleStream.re_purpose === 0) {
      setScheduleStream({ ...scheduleStream, re_purpose: 1 });
    } else {
      setScheduleStream({ ...scheduleStream, re_purpose: 0 });
    }
  }

  const sendAllData = (e) => {
    e.preventDefault();
    let newDate = new Date(new Date().toLocaleString("en-US", { timeZone: scheduleStream.timezone }));

    // if(newDate.getHours() > scheduleStream.hours){
    //   swal('Oops..','You have selected past hour')
    // }
    // else if(newDate.getMinutes() > scheduleStream.minutes){
    //   swal('Oops..','You have selected past minute')
    // } else{
    //
    //
    //
    // }
    setLoader(true);
    setScheduleStream({ ...scheduleStream, timezone: selectedTimezone.value });
    dispatch(addSchedulerContent(scheduleStream, history, setShowPopup, setLoader));




  }

  const countStep = (value) => {
    setNextId(value);
  }

  const lastStep = (e) => {
    setSchedule(true);
    setNextId(4);
  }

  const targetBack = (e) => {
    if (e < 1) {
      setNextId(1);
    } else {
      setNextId(e);
    }
  }

  const targetNext = (e) => {
    if (e > 4) {
      setNextId(1);
    } else {

      if (scheduleStream.user_client_id) {
        setNextId(2);
      }
      if (scheduleStream.content_stream_id) {
        setNextId(3);

        if (nextId === 1) {
          setNextId(2);
        }

      }
      if (scheduleStream.social_accounts.length > 0) {
        if (nextId === 2) {
          setNextId(3);
        }
        else if (nextId === 1) {
          setNextId(2);
        }
        else {
          setSchedule(true);
          setNextId(4);
        }
      }
    }
  }


  useEffect(() => {
    setScheduleStream({ ...scheduleStream, schedule_date: inputDate.date });
  }, [scheduleStream.user_client_id])

  useEffect(() => {
    setScheduleStream({ ...scheduleStream, timezone: selectedTimezone.value });
  }, [selectedTimezone])

  useEffect(() => {
    setScheduleStream({ ...scheduleStream, timezone: timezone, schedule_type: inputDate.schedule_type });
  }, []);

  useEffect(() => {
    setScheduleStream({ ...scheduleStream, schedule_type: inputDate.schedule_type });
  }, [inputDate])

  return (
    <>
      <section className="popup-sec" style={{ padding: "30px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <ul className="nav d-flex justify-content-center" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a onClick={(e) => countStep(1)} className={`nav-link text-center text-white ${nextId === 1 ? 'active' : ''}`} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Client</a>
                </li>
                <li className="nav-item">
                  <a onClick={(e) => countStep(2)} className={`nav-link text-center text-white ${nextId === 2 ? 'active' : ''}  ${scheduleStream.user_client_id ? '' : 'hide-button'}`} id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Stream</a>
                </li>
                <li className="nav-item">
                  <a onClick={(e) => countStep(3)} className={`nav-link text-center text-white ${nextId === 3 ? 'active' : ''} ${scheduleStream.content_stream_id ? '' : 'hide-button'}`} id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Account</a>
                </li>
                <li className="nav-item">
                  <a onClick={(e) => lastStep(scheduleStream.social_accounts.length)}
                    className={`nav-link text-center text-white ${nextId === 4 ? 'active' : ''}  ${scheduleStream.social_accounts.length > 0 ? '' : 'hide-button'}`} id="pills-contacts-tab"
                    data-toggle="pill" href="#pills-contacts" role="tab" aria-controls="pills-contacts" aria-selected="false">Schedule</a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className={`tab-pane overflow-hide fade ${nextId === 1 ? 'active show' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="icon-group mt-5 d-flex flex-wrap">
                    {
                      clients.length > 0 ?
                        clients.map((client) => {
                          return (
                            <>
                              <div className="icon-pop text-center" style={{ position: "relative" }}>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="user_client_id"
                                    id="exampleRadios1" value={client.id}
                                    onChange={(e) => onInputChange(e)} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                  </label>
                                </div>
                                <span>
                                  <img src={client.image} alt="fb-img" className="img-fluid" />
                                  <span className={`check-span ${scheduleStream.user_client_id == client.id ? 'show-span' : ''}`}><i className="fas fa-check"></i></span>
                                </span>
                                <h6 title={client.name}>{client.name}</h6>
                              </div>
                            </>
                          )
                        })
                        : <p>No Client Account Created</p>
                    }
                  </div>
                </div>
                <div className={`tab-pane overflow-hide fade ${nextId === 2 ? 'active show' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

                  <div className="icon-group mt-5 d-flex flex-wrap" style={{ overflowY: "scroll", height: "15vh" }}>
                    {
                      streams.length > 0 ?
                        streams.map((stream) => {

                          return (
                            <>
                              <div className="icon-pop text-center" style={{ position: "relative" }}>
                                <div className="form-check">
                                  <input className="form-check-input" type="radio" name="content_stream_id"
                                    id="exampleRadios1" value={stream.id}
                                    onChange={(e) => onInputChange(e, stream.type)} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                  </label>
                                </div>
                                <span>
                                  {
                                    stream.type === 'text' ?
                                      <img src={textStream} alt="thumbnail" className="img-fluid" />
                                      : ''
                                  }
                                  {
                                    stream.type === 'formatted' ?
                                      <img src={textStream} alt="thumbnail" className="img-fluid" />
                                      : ''
                                  }
                                  {
                                    stream.type === 'image' ?
                                      <img
                                        src={Object.entries(stream.stream).length !== 0 ? stream.stream.thumbnails : imageStream}
                                        alt className="img-fluid" />
                                      : ''
                                  }
                                  {
                                    stream.type === 'video' ?
                                      <img
                                        src={Object.entries(stream.stream).length !== 0 ? stream.stream.thumbnails : videoStream}
                                        alt className="img-fluid" />
                                      : ''
                                  }
                                  <span className={`check-span ${scheduleStream.content_stream_id == stream.id ? 'show-span' : ''}`}><i className="fas fa-check"></i></span>
                                </span>
                                <h6 title={stream.title} >{stream.title}</h6>
                              </div>
                            </>
                          )
                        })
                        : <p>No Content Stream Created</p>
                    }
                  </div>
                </div>
                <div className={`tab-pane overflow-hide fade ${nextId === 3 ? 'active show' : ''}`} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                  <div className="icon-group mt-5 d-flex flex-wrap" style={{ overflowY: "scroll", height: "15vh" }}>
                    {
                      socials.filter(social => social.user_id === scheduleStream.user_client_id).length > 0 ?
                        socials.filter(social => social.user_id === scheduleStream.user_client_id).map((social) => {
                          return (
                            <>
                              {(() => {
                                if (social.type === 'reddit' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={redditImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                } else if (social.type === 'vimeo' && streamType === 'video' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={vimeoImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'facebook' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={facebookImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'telegram' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={telegramImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'twitter' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={twitterImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'youtube' && streamType === 'video' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={youtubeImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'linkedin' && streamType !== 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={linkedInImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'wordpress') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={wordpressImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                                else if (social.type === 'medium' && streamType === 'text' || streamType === 'formatted') {
                                  return (
                                    <>
                                      <SocialAccountType engData={engData} type={social.type} image={mediumImage} social={social} onInputSocial={onInputSocial} scheduleStream={scheduleStream} />
                                    </>
                                  )
                                }
                              })()}

                            </>
                          )
                        })
                        : <p>No Social Account Connected for Selected Client</p>
                    }
                  </div>
                </div>
                <div className={`tab-pane fade ${nextId === 4 ? 'active show' : ''}`} id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tab">
                  <div className="row" style={{ marginTop: "4rem" }}>
                    {/*<div className="col-md-6">*/}
                    {/*  <label>Hours</label>*/}
                    {/*  <select className="form-control" name="hours" onChange={(e)=>onInputChange(e)}>*/}
                    {/*    <option selected value="01">01</option>*/}
                    {/*    <option value="02">02</option>*/}
                    {/*    <option value="03">03</option>*/}
                    {/*    <option value="04">04</option>*/}
                    {/*    <option value="05">05</option>*/}
                    {/*    <option value="06">06</option>*/}
                    {/*    <option value="07">07</option>*/}
                    {/*    <option value="08">08</option>*/}
                    {/*    <option value="09">09</option>*/}
                    {/*    <option value="10">10</option>*/}
                    {/*    <option value="11">11</option>*/}
                    {/*    <option value="12">12</option>*/}
                    {/*    <option value="13">13</option>*/}
                    {/*    <option value="14">14</option>*/}
                    {/*    <option value="15">15</option>*/}
                    {/*    <option value="16">16</option>*/}
                    {/*    <option value="17">17</option>*/}
                    {/*    <option value="18">18</option>*/}
                    {/*    <option value="19">19</option>*/}
                    {/*    <option value="20">20</option>*/}
                    {/*    <option value="21">21</option>*/}
                    {/*    <option value="22">22</option>*/}
                    {/*    <option value="23">23</option>*/}
                    {/*    <option value="24">24</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-6">*/}
                    {/*  <label>Minutes</label>*/}
                    {/*  <select className="form-control" name="minutes" onChange={(e)=>onInputChange(e)}>*/}
                    {/*    <option selected value="00">00</option>*/}
                    {/*    <option value="05">05</option>*/}
                    {/*    <option value="10">10</option>*/}
                    {/*    <option value="15">15</option>*/}
                    {/*    <option value="20">20</option>*/}
                    {/*    <option value="25">25</option>*/}
                    {/*    <option value="30">30</option>*/}
                    {/*    <option value="35">35</option>*/}
                    {/*    <option value="40">40</option>*/}
                    {/*    <option value="45">45</option>*/}
                    {/*    <option value="50">50</option>*/}
                    {/*    <option value="55">55</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                    <div className="col-md-6">
                      <label>Pick a Time</label>
                      {showTime && (
                        <TimeKeeper
                          time={time}
                          onChange={(newTime) => onTimeKeepChange(newTime.formatted24)}
                          hour24Mode
                          forceCoarseMinutes
                          doneButton={(newTime) => (
                            <div
                              style={{
                                textAlign: 'center',
                                padding: '10px 0',
                                backgroundColor: "#16d489",
                                color: "#ffffff",
                                cursor: "pointer"
                              }}
                              onClick={() => setShowTime(false)}
                            >
                              Close
                            </div>
                          )}
                        />
                      )}
                      {!showTime && <button className="btn-submit" onClick={() => setShowTime(true)}>
                        <i className="fa fa-clock-o" style={{ fontSize: "20px" }}></i></button>
                      }
                    </div>
                    <div className="col-md-6">
                      <div className="select-wrapper" style={{ marginTop: "1rem" }}>
                        <label>Timezone</label>
                        <TimezoneSelect
                          value={selectedTimezone.value}
                          onChange={setSelectedTimezone}
                        />
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "1rem" }}>
                      <div className="alert alert-primary" role="alert">
                        Hit "repurpose" to have AgencyReel repost the content without having to reschedule again. AgencyReel will select your best content from that particular content stream to post each time without scheduling again.
                      </div>
                    </div>
                    <div className="col-md-12">
                      <input type="checkbox" name="re_purpose" value="1" onChange={(e) => onCheckInput(e)} />
                      <label style={{ margin: "0px 10px" }}>
                        Repurpose
                        <span
                          style={{ fontSize: "12px", color: "#3e99f3" }}
                          className="fas fa-question-circle ml-1"
                          data-for="mainTooltip"
                          data-iscapture="true"
                          data-tip="Checking 'Repurpose' will continue repurposing the content in a Content Stream even when it runs out of new content. If you scheduled daily posting and the content stream runs out of new content after 4 days then 'Repurpose' will reuse the existing content and repeat publish them."></span>
                      </label>
                      <ReactTooltip
                        id="mainTooltip"
                        place="right"
                        multiline={true}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="back-next-btn mt-4">
                <ul className="list-inline d-flex justify-content-end m-0">
                  <li><a style={{ zIndex: "0", cursor: "pointer" }} onClick={(e) => targetBack(nextId - 1)} className="button">Back</a></li>
                  <li>
                    {
                      schedule ?
                        nextId === 4 ? <button style={{ zIndex: "0" }} onClick={(e) => sendAllData(e)} disabled={loader} className="btn-submit button">
                          {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Schedule
                        </button>
                          : <a className="nav-link active text-center text-white button"
                            style={{ zIndex: "0", cursor: "pointer" }} onClick={(e) => targetNext(nextId + 1)} >Next</a>
                        : <a className={`nav-link active text-center text-white button ${scheduleStream.user_client_id === null ? 'opacity-button' : ''}`}
                          style={{ zIndex: "0", cursor: "pointer" }} onClick={(e) => targetNext(nextId + 1)} >Next</a>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Popup;
