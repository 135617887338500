import React from 'react'
import GDPRContent from './SubComponent/GDPRContent'
import GDPRemil from './SubComponent/GDPRemil'
import Navbar from './SubComponent/Navbar'
import { Helmet } from "react-helmet";
import BannerLinebar from "./SubComponent/BannerLinebar";
import { useSelector } from 'react-redux';
import { appName } from '../global/global';

function GDPR() {
    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Privacy </title>
            </Helmet>
            <div className="wrapper-box">
                <Navbar />
                <BannerLinebar />
                <GDPRemil />
                <GDPRContent />
            </div>
        </>
    )
}

export default GDPR;
