import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";

function ReportClients() {

    const auth = useSelector(state => state.auth);
    const history = useHistory();

    const today = new Date();
    const months = ['Jan', 'Fed', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const [agencyData, setAgencyData] = useState([]);
    const [agencyStatus, setAgencyStatus] = useState(false);

    const [years, setYears] = useState('');
    const [appClients, setAppClient] = useState(auth.appClients);
    const [clientCount, setClientCount] = useState(auth.appClients.length);
    const [showPopup, setShowPopup] = useState(false);
    const [clientReport, setClientReport] = useState({
        id: null,
        month: today.getMonth() >= 9 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1),
        year: today.getFullYear()
    })

    const [q, setQ] = useState('');
    const getQ = (e) => {
        setQ(e.target.value)
    };

    const search = (users) => {
        return users.filter(
            user => user.name.toLowerCase().indexOf(q.toLowerCase()) > -1 || user.email.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    };

    const openReport = (e, id) => {
        setShowPopup(true);
        setClientReport({ ...clientReport, id: id });
    }

    const onInputChange = (e) => {
        setClientReport({ ...clientReport, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        history.push(`/generate-report/${clientReport.id}/${clientReport.month}/${clientReport.year}`);
    }

    const fetchAgency = async () => {
        await axios({
            method: "POST",
            url: `${baseURL}fetch-agency`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAgencyData(res.data.data);
                setAgencyStatus(true);
            }
        }).catch(err => {
        })
    }


    useEffect(() => {
        fetchAgency();
    }, [])


    useEffect(() => {
        let years = [];
        for (let i = 2021; i <= today.getFullYear(); i++) {
            years = years.concat(i);
        }
        setYears(years);
    }, []);

    useEffect(() => {
        setAppClient(auth.appClients);
        setClientCount(auth.appClients.length);

    }, [auth.appClients]);

    return (
        <div id="hole_page">
            <section className="sec-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-4 d-flex align-items-center">
                            <div className="sec-1-heading newline">
                                <ul className="list-inline m-0 d-flex">
                                    <li><NavLink to="#">My Reports</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-8  d-flex justify-content-lg-end">
                            <div className="search-add d-flex align-items-center ">
                                <div className="search-container mr-lg-3">
                                    <form>
                                        <input type="text" placeholder="Search.." onChange={(e) => getQ(e)} name="search" />
                                        <button type="submit"><i className="fa fa-search" /></button>
                                    </form>
                                </div>
                                {/* <div className="add d-flex ml-md-2 ml-lg-0 align-items-center">
                                    {
                                        auth.user.client == "0" ?
                                            <Link to="/add-client" className="button"><span><i className="fas fa-plus-circle mr-2" /></span>  Add Client</Link>
                                            : ''
                                    }

                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="deshborad-sec dashboard-custom" style={{ padding: "30px 0px 60px" }}>
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-lg-12 mb-4">
                            <Link to="/agency" className="button btn-submit agency-btn" title={agencyStatus ? agencyData.name : 'Add Agency'}>
                                {
                                    agencyStatus ? <i className="fa fa-pencil mr-1"></i> : <i className="fa fa-plus mr-1"></i>
                                }
                                {
                                    agencyStatus ? agencyData.name : 'Add Agency'
                                }
                            </Link>
                        </div>
                        {
                            appClients.length > 0 ?
                                search(appClients).map((client) => {
                                    return (
                                        <div className="col-lg-3 col-md-6" key={client.id}>
                                            <div className="deshborad-box  clientcard-box image">
                                                <img src={client.thumbnail !== "" ? client.thumbnail : client.image} alt className="w-100" />
                                                <div className="icon">
                                                    <ul className="list-inline iconcenter d-flex">
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="text-center reports mt-4">
                                                <h6 title={client.name} className="text-center ">{client.name}</h6>
                                                <button onClick={(e) => openReport(e, client.id)}
                                                    style={{ margin: "auto" }} className="button btn-submit mt-3">
                                                    Create Report
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="col-md-12">
                                    <h5 className="text-center">You have no client to generate a report for.</h5>
                                </div>
                        }
                    </div>
                </div>
            </section>

            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{ padding: "15px 20px" }} method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Select Year & Month</h5>
                            <button type="button" className="close" onClick={(e) => setShowPopup(false)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Select Year</label>
                                    <select className="form-control" name="year" onChange={(e) => onInputChange(e)}>
                                        {/*<option value="2021">2021</option>*/}
                                        {
                                            years.length > 0 ?
                                                years.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item} selected={item === today.getFullYear()} >{item}</option>
                                                    )
                                                })
                                                : ''
                                        }
                                    </select>
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Select Month</label>
                                    <select className="form-control" name="month" onChange={(e) => onInputChange(e)}>
                                        {
                                            months.map((item, index) => {
                                                return (
                                                    <>
                                                        <option
                                                            selected={today.getMonth() === index}
                                                            value={`${index >= 9 ? index + 1 : '0' + (index + 1)}`}>
                                                            {item}
                                                        </option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit">Generate Report</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ReportClients;
