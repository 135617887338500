import React, { useEffect, useState } from "react";
import { baseURL, feUrl } from "../../global/global";
import Alert from "../alert/alert";
import vimeoImage from "../../images/vimeo_new.png";
import { useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring";
import { checkNetworkStatus } from "../../store/actions/socialAccount";
import telegramPdf from "../../images/pdf/AgencyReel-Telegram.pdf";
import axios from "axios";

const VimeoConnect = ({ checkAccount, editClient }) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [vimeoInput, setVimeoInput] = useState({
        user_id: editClient.id,
        client_id: null,
        client_secret: null
    });

    // const connectVimeo = (e) =>{
    //     setShowPopup(true);
    // }

    const onInputChange = (e) => {
        setVimeoInput({ ...vimeoInput, [e.target.name]: e.target.value });
    }

    const closeConnectModel = () => {
        setShowPopup(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('client_id', vimeoInput.client_id);
        formData.append('client_secret', vimeoInput.client_secret);
        formData.append('user_id', vimeoInput.user_id);
        formData.append('key', randomString);
        formData.append('token', auth.token);

        axios({
            method: "POST",
            url: `${baseURL}connect-vimeo`,
            data: formData,
        }).then(res => {
            console.log(res);
        }).catch(err => {

        })
    }

    let interval;

    const connectVimeo = (e) => {
        e.preventDefault()
        setShowPopup(false);

        let url = `${baseURL}connect-vimeo?token=${auth.token}&key=${randomString}&user_id=${editClient.id !== null ? editClient.id : auth.user.id}&client_id=${vimeoInput.client_id}&client_secret=${vimeoInput.client_secret}`;
        let myWindow = window.open(encodeURI(url), 'mywindow', 'width=500,height=600');

        interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString, setCheckStatus, interval, myWindow));
            }

        }, 5000);

    }

    useEffect(() => {
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <>
            <Alert />
            <a className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={(e) => setShowPopup(true)} href="#">
                <img className={`${checkAccount === false ? 'client-img-opacity' : ''}`} src={vimeoImage} alt="vimeo" />
            </a>

            <Alert />
            <div className={`modal ${showPopup ? "fade show d-block" : ""}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <form className="modal-content" style={{ padding: "15px 20px" }} method="post" onSubmit={(e) => connectVimeo(e)}>
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title text-center">Connect Vimeo</h5>
                            <button type="button" className="close" onClick={(e) => closeConnectModel(e)} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Client ID</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Client ID" required
                                        name="client_id" onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="exampleInputEmail1">Client Secret</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Client Secret" required
                                        name="client_secret" onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                                <a href={`${feUrl}article-details?id=9&cid=5`} target="_blank">How to get this information ?</a>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <button type="submit" disabled={loader}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Connect
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default VimeoConnect;